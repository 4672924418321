import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import axios from 'axios';
import DotPreloader from 'ui-kit/DotPreloader';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';
import Tooltip from 'ui-kit/Tooltip';
import ModalContext from 'utils/contexts/Modal';
import { useAxiosStateWithRefetchNoFirstCall } from 'utils/hooks/axiosHook';
import { changeCardName } from 'utils/services/request/card';
import { convertCardNumber, getCardsCommissionByProvider } from 'utils/number';
import {
  CARD_TYPES,
  MTS,
  SYSTEM_CARD_TYPES,
  ALFA,
  ASSIST,
} from 'utils/constants/paymentMethods';
import { ATTACH_CARD } from 'constants/routings';
import { pushAnalyticsEvent } from 'components/App/analytics';
import RenameCardModal from 'components/Auth/UserProfile/PaymentMethodList/RenameCardModal';
import CardsCommissionInfo from 'components/Auth/UserProfile/PaymentMethodList/CardsCommissionInfo';
import {
  AttachCardButton,
  Button,
  ButtonsWrapper,
  CardLabel,
  CardName,
  CardNumber,
  CardRow,
  CardWrapper,
  СardContent,
  LoadWrapper,
  MastercardLogo,
  VisaLogo,
  CardsWrapper,
  CardsTitleWrapper,
  CardsInfoWrapper,
  BelCardLogo,
  MirLogo,
  TypesCardWrapper,
  CardIcon,
  AttachButtonWrapper,
  AttachCardsInfo,
} from '../styled-ui';

const getCardIconByType = brand => {
  const value = typeof brand === 'string' ? brand.toUpperCase() : null;
  switch (value) {
    case CARD_TYPES.BELCARD:
      return <BelCardLogo />;
    case CARD_TYPES.MIR:
      return <MirLogo />;
    case CARD_TYPES.MASTERCARD:
      return <MastercardLogo />;
    case CARD_TYPES.VISA:
      return <VisaLogo />;
    default:
      return null;
  }
};

const CardManagement = ({
  t,
  cardsInformation,
  payments,
  commissionInformation,
}) => {
  const history = useHistory();
  const [selectedProviderType, setSelectedProviderType] = useState({});
  const [cards, setCards] = useState(null);
  const [cardToRemove, setCardToRemove] = useState({
    open: false,
    id: '',
    remove: () => {},
  });
  const [cardToRename, setCardToRename] = useState({
    open: false,
    id: '',
    providerType: '',
  });
  const [cardName, setCardName] = useState('');
  const { openModal } = useContext(ModalContext);

  const attachCard = useAxiosStateWithRefetchNoFirstCall(
    selectedProviderType.attachCardRequest
  );

  useEffect(() => {
    if (cardsInformation.data && cardsInformation.loaded) {
      setCards(cardsInformation.data);
    }
  }, [cardsInformation]);

  const isCommonBindingButton = id => [ALFA, ASSIST].includes(id);

  const deleteUserCard = () => {
    setCardToRemove(prev => ({ ...prev, open: false }));

    return axios
      .post(cardToRemove.remove(cardToRemove.id))
      .then(cardsInformation.refetch)
      .catch(() =>
        openModal({
          isError: true,
          message: t('errorModal.title'),
        })
      );
  };

  useEffect(() => {
    if (attachCard?.data?.url) {
      window.location.assign(`${attachCard.data.url}`);
    } else if (attachCard?.error) {
      openModal({
        isError: true,
        message: t('profile.cardActions.attachFailed'),
      });
    }
  }, [attachCard.data, attachCard?.error]);

  const handleAttachCard = provider => {
    if (provider.id === MTS) {
      history.push(ATTACH_CARD);
    } else {
      attachCard.fetch();
    }
    setSelectedProviderType(provider);
    pushAnalyticsEvent('attach_card_click');
  };

  const handleRenameCard = (id, providerType, name) => {
    setCardToRename({
      open: true,
      id,
      providerType,
      name,
    });
    setCardName(name);
  };

  const handleSetCardName = async () => {
    setCardToRename(prev => ({ ...prev, open: false }));
    await axios.put(
      changeCardName(cardToRename?.id, cardToRename?.providerType),
      { name: cardName }
    );
    await cardsInformation.refetch();
  };

  const getCommissionInfoText = provider => {
    switch (provider) {
      case SYSTEM_CARD_TYPES.ALFA:
        return t('profile.cardActions.commissionInfoAlfa');
      case SYSTEM_CARD_TYPES.ALFA_OTHER:
        return t('profile.cardActions.commissionInfoAlfaOther');
      case SYSTEM_CARD_TYPES.ASSIST_BELARUSBANK:
        return t('profile.cardActions.commissionInfoBelarusbank');
      case SYSTEM_CARD_TYPES.MTS:
        return t('profile.cardActions.commissionInfoMts');
      default:
        return t('profile.cardActions.commissionInfo');
    }
  };

  const renderAttachButton = payment => (
    <AttachButtonWrapper>
      <AttachCardButton
        type="button"
        disabled={attachCard.fetching}
        onClick={() => handleAttachCard(payment)}
      >
        {t('profile.cardActions.attachCardAction')}
      </AttachCardButton>
    </AttachButtonWrapper>
  );

  const getCommissionInfo = type => {
    const commission = getCardsCommissionByProvider(
      commissionInformation,
      type
    );
    return (
      commission && (
        <CardsCommissionInfo
          t={t}
          commissionInformation={commission}
          text={getCommissionInfoText(type)}
        />
      )
    );
  };

  return (
    <>
      {payments?.map(payment => (
        <Fragment key={payment.id}>
          {isCommonBindingButton(payment.id) && renderAttachButton(payment)}
          <CardsWrapper>
            {payment.cardTypeList.map(cardType => (
              <TypesCardWrapper key={cardType}>
                <CardsTitleWrapper>
                  <Trans
                    i18nKey={t(`profile.cardTypeList.${cardType}`)}
                    t={t}
                  />
                </CardsTitleWrapper>
                <CardsInfoWrapper>
                  {getCommissionInfo(cardType)}
                  {!isCommonBindingButton(payment.id) &&
                    renderAttachButton(payment)}
                </CardsInfoWrapper>
              </TypesCardWrapper>
            ))}
            <AttachCardsInfo>
              {t(`profile.cardActions.attachCardInfo`)}
            </AttachCardsInfo>
            <CardLabel>
              <div>{t('profile.cardActions.attachedCards')}</div>
              <Button color="blue" onClick={cardsInformation.refetch}>
                {t('profile.cardActions.refreshCards')}
              </Button>
              <Tooltip
                type="INFO"
                text={t('profile.cardActions.refreshCardNotification')}
                placement="left"
              />
            </CardLabel>
            {cards
              ?.filter(it => it.providerType === payment.id)
              .map(({ number, brand, id, providerType, name, restricted }) => (
                <CardRow key={id}>
                  <CardWrapper>
                    <СardContent>
                      <CardNumber>
                        <CardIcon>{getCardIconByType(brand)}</CardIcon>
                        {convertCardNumber(number)}
                      </CardNumber>
                      <CardName>{name}</CardName>
                    </СardContent>
                    {restricted && (
                      <Tooltip
                        type="DISABLED"
                        text={t(
                          'profile.cardActions.restrictedCardNotification'
                        )}
                      />
                    )}
                  </CardWrapper>
                  <ButtonsWrapper>
                    <Button
                      type="button"
                      onClick={() => handleRenameCard(id, providerType, name)}
                    >
                      {t(`profile.cardActions.renameCard`)}
                    </Button>
                    <Button
                      color="red"
                      type="button"
                      onClick={() => {
                        setCardToRemove({
                          open: true,
                          id,
                          remove: payment.getRemoveCardUrl,
                        });
                      }}
                    >
                      {t(`profile.cardActions.removeCard`)}
                    </Button>
                  </ButtonsWrapper>
                </CardRow>
              ))}
            {cardsInformation.fetching && (
              <LoadWrapper>
                <DotPreloader dotColor="#0069ff" />
              </LoadWrapper>
            )}
          </CardsWrapper>
        </Fragment>
      ))}

      {cardToRemove.open && (
        <DialogModal
          Component={Dialog}
          title={t('profile.cardActions.removeCardModalTitle')}
          message={t('profile.cardActions.removeCardModalDesc')}
          acceptButtonName={t('profile.cardActions.removeCard')}
          acceptFunc={() => deleteUserCard()}
          onClose={() => setCardToRemove(prev => ({ ...prev, open: false }))}
          t={t}
        />
      )}
      {cardToRename.open && (
        <RenameCardModal
          t={t}
          value={cardName}
          onChange={e => setCardName(e.target.value)}
          onClose={() => setCardToRename(prev => ({ ...prev, open: false }))}
          onSubmit={() => handleSetCardName()}
        />
      )}
    </>
  );
};

export default CardManagement;
