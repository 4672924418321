import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, isNil } from 'ramda';
import { Button } from 'ui-kit/Button';
import { SimpleModal } from 'ui-kit/Modal/Modal';
import Spinner from 'ui-kit/Spinner';
import CopyTooltip from 'ui-kit/Tooltip/CopyTooltip';
import ErrorModal from 'components/ErrorModal';
import { ERROR_MESSAGES } from 'utils/constants/errors';
import ModalContext from 'utils/contexts/Modal';
import {
  generateReferralLink,
  getReferralCount,
  getReferrer as getReferrerRequest,
  getTransactions,
  updateReferralCurentAccount,
  withdrawBalance,
} from 'utils/services/request/referral';
import { getCurrencyBalance } from 'utils/services/request/wallets';
import {
  useAxiosStateWithRefetch,
  useAxiosStateWithRefetchNoFirstCall,
} from 'utils/hooks/axiosHook';
import PublicOfferModal from './PublicOfferModal';
import Table from './Table/Table';
import WithdrawalDataForm from './WithdrawalDataForm';
import {
  ButtonWrapper,
  Container,
  EditButton,
  EditIconButton,
  ReferralContentRow,
  ReferralLinkWrapper,
  SubTitle,
  TableMenu,
  Title,
  WithdrawalBalance,
  WithdrawalDataWrapper,
  Wrapper,
} from './styled-ui';
import { Loader } from '../styled-ui';

const TABS = {
  FEE: 'fee',
  WITHDRAWAL: 'withdrawal',
};

const TRANSACTION_TYPES = {
  WITHDRAWAL: 'WITHDRAWAL',
  REFERRER_FEE: 'REFERRER_FEE',
};

const ACTION_TYPE = 'REFERRER_BALANCE';

const EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT';

const ReferralProgram = ({ isUserVerified, t }) => {
  const { openModal } = useContext(ModalContext);
  const [isPublicOfferModal, setIsPublicOfferModal] = useState(false);
  const [isWithdrawalDataForm, setIsWithdrawalDataForm] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [referrer, setReferrer] = useState(null);
  const [activeTab, setActiveTab] = useState(TABS.FEE);
  const [feeTablePage, setFeeTablePage] = useState(0);
  const [feeTablePagination, setFeeTablePagination] = useState({});
  const [withdrawalTablePage, setWithdrawalTablePage] = useState(0);
  const [withdrawalTablePagination, setWithdrawalTablePagination] = useState(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isError, setIsError] = useState(false);
  const getReferrer = useAxiosStateWithRefetch(getReferrerRequest);
  const referralCount = useAxiosStateWithRefetchNoFirstCall(getReferralCount);
  const referralBalance = useAxiosStateWithRefetchNoFirstCall(
    getCurrencyBalance({
      currency: referrer?.currency,
      type: ACTION_TYPE,
    })
  );
  const transactionsData = {
    clientId: referrer?.clientId,
    transactionTypes:
      activeTab === TABS.FEE
        ? [TRANSACTION_TYPES.REFERRER_FEE]
        : [TRANSACTION_TYPES.WITHDRAWAL],
  };
  const feeTransactions = useAxiosStateWithRefetchNoFirstCall(
    getTransactions({ page: feeTablePage, filters: transactionsData })
  );
  const withdrawalTransactions = useAxiosStateWithRefetchNoFirstCall(
    getTransactions({ page: withdrawalTablePage, filters: transactionsData })
  );
  const isButtonWithdrawalDisabled =
    Number(referralBalance?.data?.balances[0].amount) === 0;

  const refUrl = `${window.location.origin}/?refid=${referrer?.refId}`;

  const isLoadingReferrer = getReferrer?.loaded && !getReferrer?.fetching;

  const isLoadingData =
    referralCount?.loaded &&
    !referralCount?.fetching &&
    referralBalance?.loaded &&
    !referralBalance?.fetching;

  useEffect(() => {
    if (!isEmpty(getReferrer?.data) && !isNil(getReferrer?.data)) {
      setReferrer(getReferrer?.data);
      referralCount.fetch();
      referralBalance.fetch();
      feeTransactions.fetch();
    } else if (isLoadingReferrer && !getReferrer?.error) {
      setIsLoading(false);
    } else if (getReferrer?.error) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [getReferrer?.data, getReferrer?.error, isLoadingReferrer]);

  useEffect(() => {
    if (isLoadingData) {
      setIsLoading(false);
    }
  }, [isLoadingData]);

  const setPagination = (transactions, setTablePagination) => {
    if (transactions?.data) {
      setTablePagination({
        totalPages: transactions.data.totalPages,
        totalElements: transactions.data.totalElements,
      });
    }
  };

  useEffect(() => {
    setPagination(feeTransactions, setFeeTablePagination);
    setPagination(withdrawalTransactions, setWithdrawalTablePagination);
  }, [feeTransactions?.data, withdrawalTransactions?.data]);

  const handleGetReferralLink = async () => {
    setIsSpinner(true);
    try {
      const response = await axios.post(generateReferralLink);
      if (response.status === 200) {
        await setIsPublicOfferModal(false);
        await setIsSpinner(false);
        await getReferrer.refetch();
      }
    } catch {
      setIsPublicOfferModal(false);
      setIsSpinner(false);
      openModal({
        isError: true,
        message: t('errorModal.title'),
      });
    }
  };

  const handleWithdrawBalance = async () => {
    setIsSpinner(true);
    const data = {
      amount: referralBalance?.data?.balances[0].amount,
      commission: '0',
      currency: referralBalance?.data?.balances[0].currency,
      externalId: uuidv4(),
      type: EXTERNAL_ACCOUNT,
    };
    try {
      if (isUserVerified) {
        const response = await axios.post(withdrawBalance, data);
        if (response.status === 200) {
          await openModal({
            message: t('profile.referralProgram.withdrawalSuccess'),
          });
          await referralBalance.refetch();
        }
      } else {
        openModal({
          isError: true,
          message: t('profile.referralProgram.notVerified'),
        });
      }
      await setIsSpinner(false);
    } catch (error) {
      setIsSpinner(false);
      openModal({
        isError: true,
        message: error.response?.data?.message,
      });
    }
  };

  const handleClickTableTab = tab => {
    setActiveTab(tab);
    setFeeTablePage(0);
    setWithdrawalTablePage(0);
    if (tab === TABS.FEE) {
      feeTransactions.fetch();
    } else {
      withdrawalTransactions.fetch();
    }
  };

  const onFeePageChange = page => {
    setFeeTablePage(page - 1);
    feeTransactions.refetch();
  };

  const onWithdrawalPageChange = page => {
    setWithdrawalTablePage(page - 1);
    withdrawalTransactions.refetch();
  };

  const initialValues = {
    identificationNumber: referrer?.identificationNumber || '',
    account: referrer?.account || '',
    bankAccountName: referrer?.bankAccountName || '',
    bankAccountCode: referrer?.bankAccountCode || '',
  };

  const handleAddDataForWithdrawal = async (values, actions) => {
    try {
      const response = await axios.put(updateReferralCurentAccount, {
        identificationNumber: values.identificationNumber,
        account: values.account,
        bankAccountName: values.bankAccountName,
        bankAccountCode: values.bankAccountCode,
      });
      if (response.status === 200) {
        await getReferrer.refetch();
        await setIsWithdrawalDataForm(false);
      }
    } catch (error) {
      if (error.response.data?.message === ERROR_MESSAGES.INVALID_IBAN_NUMBER) {
        actions.setErrors({
          account: t(
            'profile.referralProgram.withdrawalDataForm.errors.invalidAccount'
          ),
        });
      }
    }
  };

  useEffect(() => {
    if (referrer) {
      const arr = [];

      Object.keys(initialValues).forEach(value => {
        if (referrer[value]) {
          arr.push({ key: value, value: referrer[value] });
        }
      });

      setWithdrawalData(arr);
    }
  }, [referrer]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      {isError ? (
        <ErrorModal />
      ) : (
        <Container>
          <Title>{t('profile.referralProgram.title')}</Title>
          {isNil(referrer) ? (
            <>
              <SubTitle>{t('profile.referralProgram.subTitle')}</SubTitle>
              <ButtonWrapper width="300px">
                <Button onClick={() => setIsPublicOfferModal(true)}>
                  {t('profile.referralProgram.button')}
                </Button>
              </ButtonWrapper>
              {isPublicOfferModal && (
                <SimpleModal
                  Component={PublicOfferModal}
                  handleGetReferralLink={handleGetReferralLink}
                  onClose={() => setIsPublicOfferModal(false)}
                  isLoading={isSpinner}
                  t={t}
                />
              )}
            </>
          ) : (
            <>
              <ReferralContentRow>
                {t('profile.referralProgram.referralCount', {
                  count: referralCount?.data || 0,
                })}
              </ReferralContentRow>
              <WithdrawalBalance>
                <ReferralContentRow>
                  {t('profile.referralProgram.balance', {
                    balance: referralBalance?.data?.balances[0].amount,
                    currency: referralBalance?.data?.balances[0].currency,
                  })}
                </ReferralContentRow>
                <ButtonWrapper width="180px" height="48px" margin="0 0 0 5px">
                  <Button
                    onClick={handleWithdrawBalance}
                    disabled={isButtonWithdrawalDisabled}
                  >
                    {isSpinner ? (
                      <Spinner />
                    ) : (
                      t('profile.referralProgram.withdrawal')
                    )}
                  </Button>
                </ButtonWrapper>
              </WithdrawalBalance>
              <WithdrawalDataWrapper>
                {withdrawalData.length > 0 ? (
                  <>
                    <div>
                      {withdrawalData.map(({ key, value }) => (
                        <ReferralContentRow key={key}>
                          {t(`profile.referralProgram.${key}`, { value })}
                        </ReferralContentRow>
                      ))}
                    </div>
                    <EditButton onClick={() => setIsWithdrawalDataForm(true)}>
                      <EditIconButton />
                    </EditButton>
                  </>
                ) : (
                  <ButtonWrapper width="350px" height="48px" margin="0">
                    <Button onClick={() => setIsWithdrawalDataForm(true)}>
                      {t('profile.referralProgram.withdrawalDataForm.addData')}
                    </Button>
                  </ButtonWrapper>
                )}
              </WithdrawalDataWrapper>
              <ReferralLinkWrapper>
                <ReferralContentRow>
                  {t('profile.referralProgram.referralLink', {
                    link: refUrl,
                  })}
                </ReferralContentRow>
                <CopyTooltip t={t} copyText={refUrl} />
              </ReferralLinkWrapper>
              <TableMenu>
                {Object.values(TABS).map(tab => (
                  <Button
                    key={tab}
                    type="button"
                    onClick={() => handleClickTableTab(tab)}
                    secondary={activeTab !== tab}
                  >
                    {t(`profile.referralProgram.tabs.${tab}`)}
                  </Button>
                ))}
              </TableMenu>
              {activeTab === TABS.FEE && (
                <Table
                  tableData={feeTransactions?.data?.content}
                  totalPages={feeTablePagination?.totalPages}
                  totalRecords={feeTablePagination?.totalElements}
                  onPageChange={onFeePageChange}
                  isLoading={
                    !feeTransactions.loaded && feeTransactions.fetching
                  }
                  t={t}
                />
              )}
              {activeTab === TABS.WITHDRAWAL && (
                <Table
                  tableData={withdrawalTransactions?.data?.content}
                  totalPages={withdrawalTablePagination?.totalPages}
                  totalRecords={withdrawalTablePagination?.totalElements}
                  onPageChange={onWithdrawalPageChange}
                  isLoading={
                    !withdrawalTransactions.loaded &&
                    withdrawalTransactions.fetching
                  }
                  isWithdrawal
                  t={t}
                />
              )}
              {isWithdrawalDataForm && (
                <SimpleModal
                  t={t}
                  Component={WithdrawalDataForm}
                  onClose={() => setIsWithdrawalDataForm(false)}
                  initialValues={initialValues}
                  onSubmit={handleAddDataForWithdrawal}
                />
              )}
            </>
          )}
        </Container>
      )}
    </Wrapper>
  );
};

export default ReferralProgram;
