import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/translate/en';
import ru from './assets/translate/ru';

i18n.use(initReactI18next).init({
  resources: {
    ru,
    en,
  },
  debug: process.env.NODE_ENV === 'development',

  lng: 'ru',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
