import styled from 'styled-components';
import device from 'device';
import { ReactComponent as Close } from 'assets/images/icons/cross.svg';

export const Container = styled.div`
  flex: 0.5;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  @media ${device.mobileHeader} {
    padding: 0;
    margin-right: 0;
    flex: initial;
  }
`;
export const LogoWrapper = styled.div`
  cursor: pointer;
  > svg {
    height: 60px;
    @media ${device.desktop} {
      height: 52px;
    }
    @media ${device.laptopXL} {
      height: 45px;
    }
    @media ${device.desktop1280} {
      height: 35px;
    }
    @media (max-width: 320px) {
      width: 50vw;
    }
  }
`;

export const CloseIcon = styled(Close)`
  display: none;
  grid-area: menu;
  path {
    stroke: #fff;
  }
  @media ${device.mobileHeader} {
    display: inline;
    justify-self: end;
    padding: 24px 0;
    height: 36px;
  }
`;
