import { useLocation } from 'react-router';
import { parse } from 'query-string';
import { HOME } from 'constants/routings';
import { PATHNAME_REG_EXP } from 'utils/regexp';

export const localeCurrencies = { RU: 'RUB', BY: 'BYN', EN: 'USD' };
export const localeTags = { Russia: 'RU', Belarus: 'BY', Other: 'EN' };
export const locales = ['ru', 'en'];
export const ruLangCountries = [
  'Belarus',
  'Russia',
  'Ukraine',
  'Azerbaijan',
  'Armenia',
  'Kazakhstan',
  'Kyrgyzstan',
  'Moldova',
  'Tajikistan',
  'Uzbekistan',
  'Georgia',
  'Turkmenistan',
];

// for backward compatibility
export const getOldLocale = location => {
  const oldLocales = ['ru-BY', 'ru-RU', 'ru-EN', 'en-BY', 'en-RU', 'en-EN'];
  return oldLocales.find(locale => location.pathname.includes(locale));
};

export const setSumsubLang = country =>
  localStorage.setItem(
    'sumsub_lang',
    ruLangCountries.includes(country) ? 'ru' : 'en'
  );

export const getPartner = location => {
  const query = parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return query.partner;
};

export const getPathname = location => {
  if (location.pathname) {
    return location.pathname !== HOME
      ? `${location.pathname.replace(PATHNAME_REG_EXP, '')}${location.search}${
          location.hash
        }`
      : `${HOME}${location.search}${location.hash}`;
  }
  return '';
};

export const getClearPathname = location => {
  if (location.pathname) {
    return location.pathname !== HOME
      ? `${location.pathname.replace(PATHNAME_REG_EXP, '').replace(/\/+$/, '')}`
      : `${HOME}${location.search}${location.hash}`;
  }
  return '';
};

export const getLocaleFromPath = value => {
  return value ? value.substring(1, 6) : '';
};

export const getLocaleCurrency = country => {
  const locale =
    Object.keys(localeTags).find(value => country === value) || 'Other';
  return localeCurrencies[localeTags[locale]];
};

export const isAvailableLocale = () => {
  return locales.includes(localStorage.getItem('locale'));
};

export const setUserLocale = country => {
  const locale = ruLangCountries.includes(country) ? 'ru' : 'en';
  return isAvailableLocale()
    ? localStorage.getItem('locale')
    : localStorage.setItem('locale', locale);
};

export const getUserLocale = () => {
  return isAvailableLocale() ? localStorage.getItem('locale') : 'ru';
};

export const removeUrlParams = () =>
  window.history.replaceState(null, null, window.location.href?.split('?')[0]);

export const useQuery = () => new URLSearchParams(useLocation().search);
