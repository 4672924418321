import React from 'react';
import { withRouter } from 'react-router-dom';
import { HOME } from 'constants/routings';
import { pushAnalyticsEvent } from 'components/App/analytics';
import { ReactComponent as MainLogo } from 'assets/images/light-logo.svg';
import { Container, LogoWrapper, CloseIcon } from './styled-ui';

const Logo = ({ history, isOpen, toggleNav }) => {
  const onClickLogo = () => {
    toggleNav(undefined, true);
    history.push(HOME);
    pushAnalyticsEvent('logo_button_click');
  };
  return (
    <Container>
      <LogoWrapper onClick={onClickLogo}>
        <MainLogo />
      </LogoWrapper>
      {isOpen && <CloseIcon onClick={toggleNav} />}
    </Container>
  );
};

export default withRouter(Logo);
