import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { HOME } from 'constants/routings';
import Steps from 'ui-kit/Steps';
import { Button } from 'ui-kit/Button';
import ClientContext from 'utils/contexts/Client';
import UserContext from 'utils/contexts/User';
import { getClientTestingNeeded } from 'utils/user';
import {
  getReuploadLinkDetails,
  reuploadLinkDetail,
  verificationDocumentsComplete,
  verificationDocumentsCompleteReupload,
} from 'utils/services/request/verifications';
import { userStatuses } from 'utils/constants/statutes';
import {
  REGULA_REUPLOAD_LINK_ERRORS,
  REGULA_REUPLOAD_LINK_STATUSES,
} from 'utils/constants/regula';
import { useAxiosStateWithRefetchNoFirstCall } from 'utils/hooks/axiosHook';
import { getRegulaFormData } from './utils';
import FaceLiveness from './FaceLiveness';
import ConfirmForm from './ConfirmForm';
import TestForm from './TestForm';
import Loader from '../Loader';
import CameraSnapshot from './CameraSnapshot';
import {
  ContentWrapper,
  LoaderWrapper,
  NotificationWrapper,
  RegulaWrapper,
} from './styled-ui';

const Regula = () => {
  const { t } = useTranslation();
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const [images, setImages] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isCompleteRequestError, setIsCompleteRequestError] = useState(false);
  const [reuploadRedirect, setReuploadRedirect] = useState(null);
  const [livenessResult, setLivenessResult] = useState(null);
  const isClientTestingRequired = getClientTestingNeeded(client);
  const { reupload: reuploadId } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const reuploadDetails = useAxiosStateWithRefetchNoFirstCall(
    getReuploadLinkDetails(reuploadId)
  );
  const getErrorMessage = message => {
    if (currentPage) {
      return '';
    }
    switch (message) {
      case REGULA_REUPLOAD_LINK_ERRORS.LINK_EXPIRED: {
        return t('verification.reuploadIdNotFound');
      }
      case REGULA_REUPLOAD_LINK_ERRORS.LINK_PROCESSED: {
        return t('verification.reuploadIdProcessed');
      }
      default:
        return '';
    }
  };
  const reuploadData = reuploadDetails.data || null;
  const isReupload =
    reuploadData?.identityDocument ||
    reuploadData?.proofOfAddress ||
    reuploadData?.selfie;
  const isChangeOnlyLiveness =
    !reuploadData?.identityDocument &&
    !reuploadData?.proofOfAddress &&
    reuploadData?.selfie;
  const completeUrl = isReupload
    ? verificationDocumentsCompleteReupload
    : verificationDocumentsComplete;

  useEffect(() => {
    const data = client?.data;
    if (reuploadId) {
      setIsLoader(false);
      reuploadDetails.refetch();
    } else if (client.userConfirmed) {
      user.refetch();
      window.location.href = HOME;
    } else if (data?.status === userStatuses.NOT_VERIFIED) {
      setCurrentPage(1);
      setIsLoader(false);
    } else if (
      data?.status === userStatuses.PENDING ||
      isClientTestingRequired
    ) {
      setCurrentPage(4);
      setIsLoader(false);
    } else {
      setRedirect(true);
    }
  }, [reuploadId, client, user, isClientTestingRequired]);

  useEffect(() => {
    const isReuploadDetails =
      reuploadDetails.data && reuploadDetails.loaded && !livenessResult;
    if (
      isReuploadDetails &&
      reuploadDetails.data.status === REGULA_REUPLOAD_LINK_STATUSES.COMPLETE
    ) {
      setReuploadRedirect(t('verification.reuploadIdCompleted'));
    } else if (isReuploadDetails) {
      setCurrentPage(2);
      setIsLoader(false);
    }
    if (
      !reuploadDetails.data &&
      reuploadDetails.loaded &&
      !reuploadDetails.error
    ) {
      setReuploadRedirect(t('verification.reuploadIdNotFound'));
      setIsLoader(false);
    }
    if (reuploadDetails.error && reuploadDetails.loaded) {
      const errorMessage = getErrorMessage(reuploadDetails?.error?.message);
      setReuploadRedirect(errorMessage);
      setIsLoader(false);
    }
  }, [reuploadDetails]);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const getCompleteRequestData = async () => {
    let data;
    if (isReupload) {
      data = await getRegulaFormData(images);
      if (livenessResult) {
        data.append('livenessPath', livenessResult);
      }
    } else {
      data = { livenessPath: livenessResult || '' };
    }
    return data;
  };

  const submitHandler = async nextPageHandler => {
    try {
      setIsLoader(true);
      const data = await getCompleteRequestData();
      await axios.post(completeUrl, data);
      if (isReupload) {
        await axios.put(reuploadLinkDetail(reuploadId));
      }
      nextPageHandler();
    } catch {
      setIsCompleteRequestError(true);
    } finally {
      setIsLoader(false);
    }
  };

  const submitLivenessHandler = async () => {
    if (isChangeOnlyLiveness) {
      await submitHandler(() => setCurrentPage(4));
    } else {
      nextPage();
    }
  };
  const submitDocumentsHandler = async () => {
    await submitHandler(() => nextPage());
  };

  const items = [
    {
      title: t('verification.steps.confirm.title'),
      description: t('verification.steps.confirm.description'),
      content: <ConfirmForm callbackProps={{ nextPage }} />,
    },
    {
      title: t('verification.steps.liveness.title'),
      description: t('verification.steps.liveness.description'),
      content: (
        <FaceLiveness
          handleOnSubmit={submitLivenessHandler}
          handleSaveResult={setLivenessResult}
          reuploadData={reuploadData}
          isReupload={isReupload}
        />
      ),
    },
    {
      title: t('verification.steps.documents.title'),
      description: t('verification.steps.documents.description'),
      content: (
        <CameraSnapshot
          handleOnSubmitComplete={submitDocumentsHandler}
          handleLoader={setIsLoader}
          livenessResult={livenessResult}
          reuploadData={reuploadData}
          isReupload={isReupload}
          images={images}
          setImages={setImages}
        />
      ),
    },
    {
      title: t('verification.steps.test.title'),
      description: t('verification.steps.test.description'),
      content: <TestForm callbackProps={{ setIsLoader }} />,
    },
  ];

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      <RegulaWrapper>
        {currentPage && !isCompleteRequestError && (
          <>
            <Steps currentPage={currentPage} items={items} />
            <ContentWrapper>
              {currentPage && items[currentPage - 1].content}
            </ContentWrapper>
          </>
        )}
        {isLoader && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!!reuploadRedirect && (
          <NotificationWrapper>
            <div>{reuploadRedirect}</div>
            <div>
              <Button padding="8px 16px" onClick={() => setRedirect(true)}>
                {t('noMatch.redirectToHomePage')}
              </Button>
            </div>
          </NotificationWrapper>
        )}
        {isCompleteRequestError && (
          <NotificationWrapper>
            <div>{t('verification.documentsProcess.unknownError')}</div>
            <div>
              <Button
                padding="8px 16px"
                onClick={() => setIsCompleteRequestError(false)}
              >
                {t('verification.buttonToBack')}
              </Button>
            </div>
          </NotificationWrapper>
        )}
      </RegulaWrapper>
    </>
  );
};

export default Regula;
