export const ORDER_PHASES = {
  REQUEST: 'REQUEST',
  OPERATOR_SUBMIT: 'OPERATOR_SUBMIT',
  USER_CONFIRMATION: 'USER_CONFIRMATION',
  FIAT_PREPARATION: 'FIAT_PREPARATION',
  FIAT_PROCESSING: 'FIAT_PROCESSING',
  CRYPT_PENDING: 'CRYPT_PENDING',
  CRYPT_SELECTION: 'CRYPT_SELECTION',
  CRYPT_CONFIRMATION: 'CRYPT_CONFIRMATION',
  USER_REJECTION: 'USER_REJECTION',
  LIFETIME_EXPIRATION: 'LIFETIME_EXPIRATION',
  DECLINED: 'DECLINED',
  ARREST: 'ARREST',
  CHANGED_BY_TIMEOUT: 'CHANGED_BY_TIMEOUT',
  CHANGED_BY_INVALID_AMOUNT: 'CHANGED_BY_INVALID_AMOUNT',
};

export const REJECTED_PHASES = [
  ORDER_PHASES.USER_REJECTION,
  ORDER_PHASES.DECLINED,
  ORDER_PHASES.LIFETIME_EXPIRATION,
  ORDER_PHASES.ARREST,
];
