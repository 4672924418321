import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { SimpleModal } from 'ui-kit/Modal/Modal';
import { ERROR_STATUSES } from 'utils/constants/errors';
import { PAYMENT_METHODS } from 'utils/constants/profileTabs';
import COUNTRY_CODES from 'utils/constants/countryCodes';
import AttachCardNumberModal from 'components/Auth/UserProfile/PaymentMethodList/AttachCardNumberModal';

const AttachCard = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const attachCardNumberSchema = () =>
    Yup.object().shape({
      cardNumber: Yup.string()
        .required(t('modals.errors.requiredField'))
        .min(16, t('profile.cardActions.attachCardWrongFormat')),
      country: Yup.string().required(t('modals.errors.requiredField')),
    });

  const getCountryFullName = code => {
    const { name } = COUNTRY_CODES.find(it => it.code === code);
    return name?.toUpperCase();
  };

  const handleAttachCardNumber = (values, actions) => {
    return axios
      .post(`${process.env.REACT_APP_MTS_URL}/card/bind`, {
        cardNumber: values.cardNumber,
        country: getCountryFullName(values.country),
      })
      .then(() => {
        actions.resetForm();
        history.push(`/profile?tab=${PAYMENT_METHODS}&card_bind=success`);
      })
      .catch(e => {
        if (e.response?.data?.status === ERROR_STATUSES.CARD_ALREADY_EXISTS) {
          actions.setErrors({
            cardNumber: t('profile.cardActions.attachCadrdExists'),
          });
        } else if (
          e.response?.data?.status === ERROR_STATUSES.CARD_BINDING_ERROR
        ) {
          actions.setErrors({
            cardNumber: t('profile.cardActions.attachCadrdBindingError'),
          });
        } else if (
          e.response?.data?.status === ERROR_STATUSES.EMPTY_PHONE_NUMBER
        ) {
          actions.setErrors({
            cardNumber: t('profile.cardActions.attachCadrdPhoneError'),
          });
        } else
          actions.setErrors({
            cardNumber: t('profile.cardActions.attachFailed'),
          });
      });
  };
  const handleCloseAttachCardNumberModal = resetForm => {
    resetForm();
    history.push(`/profile?tab=${PAYMENT_METHODS}`);
  };

  return (
    <Formik
      initialValues={{ cardNumber: '', country: '' }}
      onSubmit={handleAttachCardNumber}
      validationSchema={attachCardNumberSchema(t)}
      validateOnChange
    >
      {formik => (
        <Form>
          <SimpleModal
            Component={AttachCardNumberModal}
            handleCloseAttachCardNumberModal={handleCloseAttachCardNumberModal}
            onClose={() => handleCloseAttachCardNumberModal(formik.resetForm)}
            onClickLogo={() =>
              handleCloseAttachCardNumberModal(formik.resetForm)
            }
            t={t}
            width="760px"
            {...formik}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AttachCard;
