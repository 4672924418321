import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { parse } from 'query-string';
import {
  AUTHORIZE,
  HOME,
  USER_PROFILE,
  VERIFICATIONS,
} from 'constants/routings';
import UserContext from 'utils/contexts/User';
import { SmallModal } from 'ui-kit/Modal/Modal';
import SignIn from 'components/Auth/SignIn';

const SignInModal = ({ history }) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectToOAuth, setRedirectToOAuth] = useState(false);
  const user = useContext(UserContext);
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const redirectPage = queryParams?.redirectPage;
  const isRedirectToHome =
    !redirectToOAuth && !redirectPage && (user.data || redirect);

  const getFullRedirectUrl = page => {
    switch (page) {
      case VERIFICATIONS: {
        return `${VERIFICATIONS}?reupload=${queryParams.reupload}`;
      }
      case USER_PROFILE: {
        return `${USER_PROFILE}?tab=${queryParams.tab}`;
      }
      default:
        return HOME;
    }
  };

  const handleGoToOAuth = () => {
    if (
      queryParams?.grant_type ||
      queryParams?.response_type ||
      queryParams?.client_id
    ) {
      setRedirectToOAuth(true);
      history.push({
        pathname: AUTHORIZE,
        search: window.location.search,
      });
    }
  };

  return (
    <>
      {isRedirectToHome && <Redirect to={HOME} />}
      {redirectPage && user.data && (
        <Redirect to={getFullRedirectUrl(redirectPage)} />
      )}
      <SmallModal
        Component={SignIn}
        onClose={() => setRedirect(true)}
        handleGoToOAuth={handleGoToOAuth}
      />
    </>
  );
};

export default SignInModal;
