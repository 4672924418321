import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import '@regulaforensics/vp-frontend-face-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import observerWC from 'utils/webComponents';
import { FACE_SDK_ACTIONS, FACE_SDK_RESULT_CODE } from 'utils/constants/regula';
import {
  getLivenessComplete,
  matchLiveness,
} from 'utils/services/request/verifications';
import { Button } from 'ui-kit/Button';
import videoFile from 'assets/video/facesdk.mp4';
import Loader from 'components/Loader';
import { getBlob, stylesRegulaComponent } from '../utils';
import {
  ErrorIcon,
  FaceLivenessWrapper,
  LivenessConditions,
  LivenessDescriptions,
  LivenessStartScreen,
  LivenessSubTitle,
  LivenessTitle,
  ScreenWrapper,
  Video,
} from './styled-ui';

const SCREEN_TYPES = {
  START: 'START',
  COMPONENT_OPEN: 'OPEN',
  MATCHING: 'MATCHING',
  COMPLETE: 'COMPLETE',
};

const FaceLiveness = ({
  handleOnSubmit,
  handleSaveResult,
  reuploadData,
  isCheckMatch,
  isReupload,
}) => {
  const { t } = useTranslation();
  const [currentScreen, setCurrentScreen] = useState(SCREEN_TYPES.START);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);
  const livenessRef = useRef(null);
  const isChangeDocs = reuploadData?.identityDocument;
  const isChangeReg = reuploadData?.proofOfAddress;
  const isChangeSelfie = reuploadData?.selfie;

  const getMatchRequestData = async image => {
    const formData = new FormData();
    const blob = await getBlob(image);
    formData.append('image', blob, 'image.jpeg');
    return formData;
  };

  const livenessProcessHandler = async ({ images, transactionId }) => {
    const livenessImage = images?.length > 0 && images[0];
    try {
      const { status, data } = await axios(
        getLivenessComplete({ transactionId })
      );
      if (status !== 200) {
        throw new Error(t('verification.regula.match.somethingWentWrong'));
      }
      if (handleSaveResult) {
        handleSaveResult(data?.portrait);
      }
      if (!isCheckMatch) {
        setCurrentScreen(SCREEN_TYPES.COMPLETE);
        return;
      }
      setCurrentScreen(SCREEN_TYPES.MATCHING);
      const matchFormData = await getMatchRequestData(livenessImage);
      const matchResponse = await axios(matchLiveness(matchFormData));
      if (matchResponse.data?.results[0]?.similarity <= 0.98) {
        throw new Error(t('verification.regula.match.error'));
      }
      setCurrentScreen(SCREEN_TYPES.COMPLETE);
    } catch {
      setCurrentScreen(SCREEN_TYPES.ERROR);
      setError(t('verification.regula.match.somethingWentWrong'));
    }
  };

  const listener = useCallback(
    // eslint-disable-next-line consistent-return
    async ({ detail: { action, data } }) => {
      switch (action) {
        case FACE_SDK_ACTIONS.PROCESS_FINISHED: {
          const code = data?.response?.code;
          if (code === FACE_SDK_RESULT_CODE.FACER_OK) {
            await livenessProcessHandler(data.response);
          }
          break;
        }
        case FACE_SDK_ACTIONS.CLOSE: {
          setCurrentScreen(SCREEN_TYPES.START);
          break;
        }
        default:
          return null;
      }
    },
    [handleSaveResult]
  );

  useEffect(() => {
    const containerLiveness = containerRef.current;
    containerLiveness.addEventListener('face-liveness', listener);
    // eslint-disable-next-line consistent-return
    return () => {
      containerLiveness.removeEventListener('face-liveness', listener);
    };
  }, [listener]);

  useLayoutEffect(() => {
    const livenessRefCurrent = livenessRef.current;
    if (!livenessRefCurrent) return;
    if (livenessRefCurrent.shadowRoot) {
      observerWC.observe(livenessRefCurrent.shadowRoot, {
        childList: true,
        subtree: true,
      });
    }
    livenessRefCurrent.settings = {
      locale: 'ru',
      recordingProcess: 2,
      url: `${process.env.REACT_APP_REGULA_URL}/kyc/regula`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      startScreen: false,
      closeDisabled: false,
      finishScreen: true,
      changeCamera: true,
      copyright: false,
      customization: stylesRegulaComponent,
    };
    livenessRefCurrent.translations = {
      ru: {
        goButton: t('verification.regula.liveness.goButton'),
      },
    };
  }, [currentScreen, t]);

  return (
    <div>
      <div ref={containerRef}>
        {currentScreen === SCREEN_TYPES.COMPONENT_OPEN && (
          <fullscreen-container>
            <FaceLivenessWrapper>
              <face-liveness ref={livenessRef} />
            </FaceLivenessWrapper>
          </fullscreen-container>
        )}
      </div>
      <ScreenWrapper>
        {currentScreen === SCREEN_TYPES.START && (
          <LivenessStartScreen>
            <LivenessTitle>
              {isReupload
                ? t('verification.regula.modes.reupload')
                : t('verification.regula.liveness.title')}
            </LivenessTitle>
            <LivenessDescriptions>
              {isReupload && t('verification.regula.modes.title')}
              {isChangeDocs && (
                <div>{t('verification.regula.modes.titleDocs')}</div>
              )}
              {isChangeReg && (
                <div>{t('verification.regula.modes.titleReg')}</div>
              )}
              {isChangeSelfie && (
                <div>{t('verification.regula.modes.titleSelfie')}</div>
              )}
            </LivenessDescriptions>
            <LivenessConditions>
              <div>
                <LivenessSubTitle>
                  {t('verification.regula.liveness.subTitle')}
                </LivenessSubTitle>
                <ul>
                  <li>{t('verification.regula.liveness.conditionFirst')}</li>
                  <li>{t('verification.regula.liveness.conditionSecond')}</li>
                  <li>{t('verification.regula.liveness.conditionThird')}</li>
                </ul>
              </div>
              <Video src={videoFile} muted loop autoPlay playsInline />
            </LivenessConditions>
            <Button
              width="200px"
              padding="10px"
              type="button"
              onClick={() => setCurrentScreen(SCREEN_TYPES.COMPONENT_OPEN)}
            >
              {t('verification.startButton')}
            </Button>
          </LivenessStartScreen>
        )}
        {currentScreen === SCREEN_TYPES.MATCHING && (
          <>
            <Loader />
            <div>{t('verification.regula.match.pending')}</div>
          </>
        )}
        {currentScreen === SCREEN_TYPES.COMPLETE && (
          <>
            <div>{t('verification.regula.match.success')}</div>
            <div>
              <Button
                width="200px"
                padding="10px"
                type="button"
                onClick={handleOnSubmit}
              >
                {t('verification.doneButton')}
              </Button>
            </div>
          </>
        )}
        {currentScreen === SCREEN_TYPES.ERROR && (
          <>
            <ErrorIcon />
            <div>{error}</div>
            <div>
              <Button
                width="200px"
                padding="10px"
                type="button"
                onClick={() => setCurrentScreen(SCREEN_TYPES.START)}
              >
                {t('verification.buttonToBack')}
              </Button>
            </div>
          </>
        )}
      </ScreenWrapper>
    </div>
  );
};
export default FaceLiveness;
