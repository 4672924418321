export const COUNTRY_CODES = {
  BY: 'BY',
  RU: 'RU',
  KZ: 'KZ',
  KG: 'KG',
  UZ: 'UZ',
  OTHERS: 'other',
};

export const CountryCodeByName = {
  RUSSIA: COUNTRY_CODES.RU,
  KYRGYZSTAN: COUNTRY_CODES.KZ,
  KAZAKHSTAN: COUNTRY_CODES.KG,
  UZBEKISTAN: COUNTRY_CODES.UZ,
};

export const DIGITAL_COUNTRY_CODES = {
  BY: '112',
};
