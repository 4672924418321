import React from 'react';
import {
  Wrapper,
  Link,
  InstagramIcon,
  TiktokIcon,
  FacebookIcon,
  YoutubeIcon,
  ViberIcon,
  XComIcon,
  TelegramIcon,
} from './styled-ui';

const links = [
  {
    id: 'instagram',
    icon: <InstagramIcon />,
    link: 'https://instagram.com/whitebird.io',
  },
  {
    id: 'tiktok',
    icon: <TiktokIcon />,
    link: 'https://www.tiktok.com/@whitebird.io',
  },
  {
    id: 'facebook',
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/people/WhiteBird/61562459422215',
  },
  {
    id: 'youtube',
    icon: <YoutubeIcon />,
    link: 'https://www.youtube.com/channel/UC4J2y0_aeKUWEL9e66HgOlg',
  },
  {
    id: 'viber',
    icon: <ViberIcon />,
    link:
      'https://invite.viber.com/?g2=AQA3Bkd%2FU5793lBoppX4gdzJF28jhNbd%2Bt44zwS7aeKNpHAA8AD3s4DcDhrXq3lT',
  },
  {
    id: 'xcom',
    icon: <XComIcon />,
    link: 'https://x.com/whitebird_io',
  },
  {
    id: 'telegram',
    icon: <TelegramIcon />,
    link: 'https://t.me/whitebirdio',
  },
];

const SocialMediaLinks = () => {
  return (
    <Wrapper>
      {links.map(link => (
        <Link key={link.id} href={link.link} target="_blank" rel="nofollow">
          {link.icon}
        </Link>
      ))}
    </Wrapper>
  );
};

export default SocialMediaLinks;
