import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/info-error.svg';
import { TooltipHover, TooltipWrapper, DisabledIcon } from './styled-ui';

const TYPE_TOOLTIP = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  DISABLED: 'DISABLED',
};

const Tooltip = ({ type, text, placement }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [offsetRight, setOffsetRight] = useState(null);
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    const target = targetRef?.current;
    if (target && isShowTooltip) {
      const targetRect = target.getBoundingClientRect();
      setOffsetLeft(`-${targetRect.left - 5}px`);
      setOffsetRight(
        `-${window.innerWidth - targetRect.right - targetRect.width - 5}px`
      );
    }
  }, [isShowTooltip]);

  // eslint-disable-next-line no-shadow
  const getIcon = type => {
    switch (type) {
      case TYPE_TOOLTIP.INFO:
        return <InfoIcon />;
      case TYPE_TOOLTIP.ERROR:
        return <ErrorIcon />;
      case TYPE_TOOLTIP.DISABLED:
        return <DisabledIcon />;
      default:
        return <InfoIcon />;
    }
  };

  return (
    <TooltipWrapper
      ref={targetRef}
      onMouseEnter={() => setIsShowTooltip(true)}
      onMouseLeave={() => setIsShowTooltip(false)}
    >
      {getIcon(type)}
      {isShowTooltip && (
        <TooltipHover
          ref={tooltipRef}
          offsetLeft={offsetLeft}
          offsetRight={offsetRight}
          placement={placement}
        >
          {text}
        </TooltipHover>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
