import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import ReactFlagsSelect from 'react-flags-select';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { COUNTRY_CODES, CountryCodeByName } from 'utils/countryCodes';
import { getCountriesAttachMtsCard } from 'utils/services/request/card';
import { FormGroup } from 'components/Verification/styled-ui';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import Error from 'ui-kit/Error';
import {
  Input,
  ButtonWrapper,
  Header,
  FormWrapper,
  LogoWrapper,
  InputWrapper,
} from './styled-ui';

const AttachCardNumberModal = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  isSubmitting,
  t,
  handleCloseAttachCardNumberModal,
  resetForm,
  onClickLogo,
}) => {
  const themeContext = useContext(ThemeContext);
  const [countriesList, setCountriesList] = useState([]);

  const mtsCountries = useAxiosState(getCountriesAttachMtsCard());

  useEffect(() => {
    if (mtsCountries.data && !mtsCountries.fetching) {
      const countries = mtsCountries.data?.map(name => CountryCodeByName[name]);
      setCountriesList(countries);
    }
  }, [mtsCountries]);

  const customLabels = {
    [COUNTRY_CODES.RU]: t(`profile.countries.${COUNTRY_CODES.RU}`),
    [COUNTRY_CODES.KZ]: t(`profile.countries.${COUNTRY_CODES.KZ}`),
    [COUNTRY_CODES.KG]: t(`profile.countries.${COUNTRY_CODES.KG}`),
    [COUNTRY_CODES.UZ]: t(`profile.countries.${COUNTRY_CODES.UZ}`),
  };

  return (
    <>
      <LogoWrapper onClick={onClickLogo}>
        <img src={themeContext.footerLogo} alt="Logo" />
      </LogoWrapper>
      <Header>{t('profile.cardActions.attachCardModalTitle')}</Header>
      <FormWrapper>
        <FormGroup>
          <ReactFlagsSelect
            customLabels={customLabels}
            placeholder={t('profile.cardActions.choiceCountry')}
            selected={values.country}
            countries={countriesList}
            onSelect={code => setFieldValue('country', code)}
          />
          {errors.country && touched.country && <Error>{errors.country}</Error>}
        </FormGroup>
        <FormGroup>
          <InputWrapper>
            <Input
              type="text"
              id="cardNumber"
              placeholder={t('profile.cardActions.attachCardTitlePlaceholder')}
              maxLength="19"
              minLength="19"
              value={values.cardNumber
                .replace(/[^0-9]/g, '')
                .replace(/\s/g, '')
                .replace(/([0-9]{4})/g, '$1 ')
                .trim()}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.cardNumber && touched.cardNumber}
            />
          </InputWrapper>

          {errors.cardNumber && touched.cardNumber && (
            <Error>{errors.cardNumber}</Error>
          )}
        </FormGroup>
        <ButtonWrapper>
          <Button
            type="submit"
            disabled={isSubmitting || !values.country || !values.cardNumber}
          >
            {t('profile.cardActions.attachCard')}
          </Button>
          <CancelChangesButton
            type="button"
            onClick={() => handleCloseAttachCardNumberModal(resetForm)}
          >
            {t('profile.cardActions.attachCardCancel')}
          </CancelChangesButton>
        </ButtonWrapper>
      </FormWrapper>
    </>
  );
};
export default AttachCardNumberModal;
