import React, { useState } from 'react';
import axios from 'axios';
import DotPreloader from 'ui-kit/DotPreloader';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';
import { DOCUMENTS } from 'utils/constants/supportMessageTopics';
import { SUPPORT } from 'constants/routings';
import { pushAnalyticsEvent } from 'components/App/analytics';
import CardsCommissionInfo from '../CardsCommissionInfo';
import {
  AttachSettlement,
  Button,
  ButtonsWrapper,
  CardNumber,
  CardRow,
  CardWrapper,
  LoadWrapper,
} from '../styled-ui';

const SettlementManagement = ({
  t,
  cardsInformation,
  getRemoveCardUrl,
  commissionInformation,
}) => {
  const [cardIdToRemove, setCardIdToRemove] = useState(null);
  const isCardsInformation = cardsInformation?.data?.length > 0;

  const deleteUserSettlement = deleteCardId => {
    setCardIdToRemove(null);
    return axios
      .delete(getRemoveCardUrl(deleteCardId))
      .then(cardsInformation.refetch);
  };

  const handleAttachSettlement = () => {
    pushAnalyticsEvent('attach_ca_click');

    return {
      pathname: SUPPORT,
      search: `?topic=${DOCUMENTS}`,
    };
  };

  return (
    <div>
      <CardsCommissionInfo
        t={t}
        commissionInformation={commissionInformation}
        text={t('profile.cardActions.commissionInfoCA')}
      />
      {cardsInformation.fetching && (
        <LoadWrapper>
          <DotPreloader dotColor="#0069ff" />
        </LoadWrapper>
      )}
      {cardsInformation?.data?.map(({ number, brand, id }) => (
        <CardRow key={id}>
          <CardWrapper>
            <CardNumber>{`${number}, ${brand}`}</CardNumber>
          </CardWrapper>
          <ButtonsWrapper>
            <Button
              color="red"
              type="button"
              onClick={() => setCardIdToRemove(id)}
            >
              {t(`profile.settlementActions.removeSettlement`)}
            </Button>
          </ButtonsWrapper>
        </CardRow>
      ))}
      <AttachSettlement
        margintop={isCardsInformation ? '0' : '20px'}
        to={handleAttachSettlement}
      >
        {t('profile.settlementActions.attachSettlement')}
      </AttachSettlement>
      {cardIdToRemove && (
        <DialogModal
          Component={Dialog}
          title={t('profile.settlementActions.removeSettlementModalTitle')}
          message={t('profile.settlementActions.removeSettlementModalDesc')}
          acceptButtonName={t('profile.settlementActions.removeSettlement')}
          acceptFunc={() => deleteUserSettlement(cardIdToRemove)}
          onClose={() => setCardIdToRemove(null)}
          t={t}
        />
      )}
    </div>
  );
};

export default SettlementManagement;
