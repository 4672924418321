export const processDocumentsUrl = `${process.env.REACT_APP_REGULA_URL}/kyc/regula/process`;

export const verificationDocumentsComplete = `${process.env.REACT_APP_REGULA_URL}/kyc/regula/complete`;

export const verificationDocumentsCompleteReupload = `${process.env.REACT_APP_REGULA_URL}/kyc/regula/complete-reupload`;

export const confirmFormRequestUrl = `${process.env.REACT_APP_REGULA_URL}/kyc/client/crypto-test`;

export const getLivenessComplete = ({ transactionId }) => ({
  method: 'get',
  url: `${process.env.REACT_APP_REGULA_URL}/kyc/regula/liveness/?transactionId=${transactionId}`,
});

export const searchLiveness = data => ({
  method: 'post',
  url: `${process.env.REACT_APP_REGULA_URL}/kyc/regula/search`,
  data,
});

export const matchLiveness = data => ({
  method: 'post',
  url: `${process.env.REACT_APP_REGULA_URL}/kyc/regula/match-by-photo`,
  data,
});

export const testComplete = {
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/testing/complete`,
};

export const verificationDocuments = {
  method: 'post',
  url: processDocumentsUrl,
};

export const getVerificationStatus = {
  method: 'get',
  url: `${process.env.REACT_APP_KYC_URL}/kyc`,
};

export const getVerificationTest = {
  method: 'get',
  url: `${process.env.REACT_APP_KYC_URL}/verification-test`,
};

export const reuploadLinkDetail = id =>
  `${process.env.REACT_APP_REGULA_URL}/kyc/client-link/management/link/details/${id}`;

export const getReuploadLinkDetails = id => ({
  method: 'get',
  url: reuploadLinkDetail(id),
});
