import styled from 'styled-components';
import device from 'device';
import { ReactComponent as Disabled } from 'assets/images/cards/disabled.svg';

export const CopyButton = styled.button`
  width: 50px;
  background-color: ${props => props.theme.grayBg};
  color: white;
  border: 0;
  -webkit-appearance: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  height: 28px;
  @media ${device.mobileL} {
    width: 30px;
    height: 24px;
  }
  @media (max-width: 280px) {
    width: 30px;
    height: 24px;
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: max-content;
  background-color: ${props => props.theme.fullBlackText};
  color: #fff;
  font-size: 15px;
  text-align: center;
  white-space: pre-line;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -50px;

  &.arrow:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
`;

export const Tooltip = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  position: relative;
  display: inline-flex;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TooltipHover = styled.div`
  display: flex;
  position: absolute;
  color: ${({ theme }) => theme.blackText};
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background: ${({ theme }) => theme.fullWhite};
  padding: 10px;
  box-shadow: 0 6px 20px rgba(16, 58, 121, 0.3);
  border-radius: 8px;
  z-index: 4;
  box-sizing: border-box;
  width: 100vh;
  max-width: 360px;
  top: 30px;
  right: ${({ placement }) => (placement === 'left' ? '0' : 'unset')};
  left: ${({ placement }) => (placement === 'center' ? '-180px' : 'unset')};

  @media ${device.tablet} {
    width: unset;
    max-width: unset;
    left: ${({ offsetLeft }) => offsetLeft};
    right: ${({ offsetRight }) => offsetRight};
  }
`;

export const DisabledIcon = styled(Disabled)`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;
