import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { HOME, SIGN_IN, USER_PROFILE } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import { PAYMENT_METHODS } from 'utils/constants/profileTabs';
import UserProfile from 'components/Auth/UserProfile';

const UserProfileWrapper = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const queryFrom = queryParams?.from;

  if (!user.data) {
    history.push(
      queryFrom
        ? `${SIGN_IN}?redirectPage=${USER_PROFILE}&tab=${PAYMENT_METHODS}`
        : HOME
    );
  }

  return <UserProfile t={t} />;
};

export default UserProfileWrapper;
