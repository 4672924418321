import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { RESTORE_PASSWORD, SIGN_UP } from 'constants/routings';
import ReactIsCapsLockActive from 'ui-kit/CapsLock';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import { FormGroup } from 'components/Verification/styled-ui';
import {
  Adding,
  ButtonWrapper,
  CloseEyeIcon,
  FormGrid,
  Header,
  Info,
  InfoForgotPassword,
  LineInfo,
  OpenEyeIcon,
  WrapEye,
} from '../styled-ui';

const signInSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .transform(value => value.trim())
      .email(t('signInForm.errors.incorrectEmailFormat'))
      .required(t('signInForm.errors.requiredField')),

    password: Yup.string()
      .required(t('signInForm.errors.requiredField'))
      .min(12, t('signInForm.errors.passMin', { count: 12 }))
      .max(128, t('signInForm.errors.passMax', { count: 128 })),
  });

const SignInForm = ({ initialValues, onSubmitHandler, t }) => {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <>
      <Header>{t('signInForm.title')}</Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validateOnChange={false}
        validationSchema={signInSchema(t)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <FormGrid onSubmit={handleSubmit}>
            <FormGroup>
              <Field
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                trimInput
                setFieldValue={setFieldValue}
                component={Input}
                placeholder="yourmail@mail.com"
                label="Email"
                autoComplete="email"
                autoFocus
              />

              {errors.email && touched.email && errors.email !== '.' && (
                <Error>{errors.email}</Error>
              )}
            </FormGroup>
            <FormGroup>
              <WrapEye>
                {isShowPassword ? (
                  <OpenEyeIcon onClick={() => setShowPassword(false)} />
                ) : (
                  <CloseEyeIcon onClick={() => setShowPassword(true)} />
                )}
              </WrapEye>
              <Field
                id="password"
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                component={Input}
                placeholder={t('signInForm.passPlaceholder')}
                label={t('signInForm.passLabel')}
                autoComplete="off"
                inputHiddenText
              />
              {errors.password && touched.password && (
                <Error maxwidth="65%">{errors.password}</Error>
              )}

              <ReactIsCapsLockActive>
                {active =>
                  active && (
                    <Error icon password>
                      {t('signUpForm.errors.capsLockEnabled')}
                    </Error>
                  )
                }
              </ReactIsCapsLockActive>
            </FormGroup>
            <InfoForgotPassword>
              <Link to={RESTORE_PASSWORD}>
                <Adding>{t('signInForm.forgotPassword')}</Adding>
              </Link>
            </InfoForgotPassword>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('signInForm.signin')}
              </Button>
            </ButtonWrapper>
          </FormGrid>
        )}
      </Formik>
      <Info>
        <LineInfo>{t('signInForm.noAccountYet')}</LineInfo>{' '}
        <Link to={SIGN_UP}>
          <Adding>{t('signInForm.register')}</Adding>
        </Link>
      </Info>
    </>
  );
};

SignInForm.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

export default SignInForm;
