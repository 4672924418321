import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { HOME } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import GeoContext from 'utils/contexts/Geo';
import { getClientTestingNeeded } from 'utils/user';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { getCodeByCountry } from 'components/Auth/helpers';
import Loader from 'components/Loader';
import ErrorModal from 'components/ErrorModal';
import { checkStep3 } from 'components/Verification/verification';
import SumSubKYCWizard from './sumSubKYCWizard';
import Step1 from './step1';
import Quiz from '../quiz';
import SumSubFrame from './sumSubFrame';

const defaultQuestions = {
  question1: null,
  question2: null,
  question3: null,
  question4: null,
  question5: null,
};

const SumSubVerification = ({ verificationStatus }) => {
  const { t } = useTranslation();
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const geo = useContext(GeoContext);
  const isClientTestingRequired = getClientTestingNeeded(client);
  const [initialValues, setInitialValues] = useState(null);
  const [initialPage, setInitialPage] = useState(
    isClientTestingRequired ? 1 : 0
  );
  const [areQuestionsLoaded, setQuestionsLoaded] = useState(false);
  const validateStep3 = useCallback(checkStep3, []);

  const verificationSubmittingForm = useAxiosSubmitingEffect({
    method: 'post',
    url: `${process.env.REACT_APP_KYC_URL}/kyc`,
  });

  const submitVerificationForm = (savedStage, bag) => {
    verificationSubmittingForm.setFormAndSubmit({
      values: savedStage,
      bag,
    });
  };
  useEffect(() => {
    if (initialValues && initialValues.userConfirmed) {
      window.location.href = HOME;
      user.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = useCallback(
    (values, bag, page) => {
      const { questions, ...cleanValues } = values;
      const savedStage = {
        ...cleanValues,
        firstStageConfirmed: true,
        secondStageConfirmed: true,
        emailVerified: true,
      };
      if (page === 0) {
        submitVerificationForm(savedStage, bag);
      }

      if (page === 1) {
        axios
          .post(
            `${process.env.REACT_APP_EXCHANGE_URL}/client/testing/complete`,
            {}
          )
          .then(() => {
            user.refetch();
            client.refetch();
          })
          .catch(e => {
            if (e.response && e.response.error) {
              // eslint-disable-next-line no-console
              console.log(e.response.error);
            }
          })
          .finally(() => {
            window.location.href = HOME;
          });
      }

      if (page === 2) {
        window.location.href = HOME;
        user.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verificationSubmittingForm]
  );

  const handlePreSave = useCallback(
    (values, bag) => {
      const { questions, ...cleanValues } = values;
      verificationSubmittingForm.setFormAndSubmit({
        values: {
          ...cleanValues,
        },
        bag,
      });
    },
    [verificationSubmittingForm]
  );

  const initForm = form => {
    let page = 0;
    if (form.data.firstStageConfirmed && isClientTestingRequired) {
      page = 1;
    }
    if (form.data.firstStageConfirmed && !isClientTestingRequired) {
      page = 2;
    }

    if (form.data.testingCompleted) {
      page = 2;
    }
    setInitialPage(page);
    setInitialValues(form.data);
  };

  useEffect(() => {
    if (
      !verificationSubmittingForm.fetching &&
      verificationSubmittingForm.loaded
    ) {
      if (verificationSubmittingForm.data) {
        if (verificationSubmittingForm !== verificationSubmittingForm.data) {
          initForm(verificationSubmittingForm);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, verificationSubmittingForm]);

  useEffect(() => {
    if (!verificationStatus.fetching && verificationStatus.loaded) {
      if (initialValues !== verificationStatus.data) {
        initForm(verificationStatus);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationStatus]);

  useEffect(() => {
    if (initialValues && initialValues.userConfirmed) {
      window.location.href = HOME;
      user.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  if (
    !initialValues &&
    !isClientTestingRequired &&
    (!initialValues ||
      verificationStatus.fetching ||
      !verificationStatus.loaded)
  ) {
    return <Loader />;
  }

  if (
    !verificationSubmittingForm.fetching &&
    verificationSubmittingForm.loaded &&
    verificationSubmittingForm.error
  )
    return <ErrorModal isReloadButton isVerification />;

  const initialFormValue = {
    ...initialValues,
    country: initialValues?.country || getCodeByCountry(geo?.data?.country),
    countryFlag: false,
    residencePermitInBelarusPresent:
      initialValues?.residencePermitInBelarusPresent || true,
    exchangeInPersonalInterests:
      initialValues?.exchangeInPersonalInterests || true,
    notUSTaxpayer: initialValues?.notUSTaxpayer || true,
    questions: defaultQuestions,
    userConfirmed: false,
  };

  return (
    <>
      <SumSubKYCWizard
        initialPage={initialPage}
        initialValues={initialFormValue}
        onSubmit={handleSubmit}
        onPreSave={handlePreSave}
        areQuestionsLoaded={areQuestionsLoaded}
        user={user}
        client={client}
        t={t}
      >
        <SumSubKYCWizard.Page>
          <Step1 />
        </SumSubKYCWizard.Page>
        <SumSubKYCWizard.Page validate={validateStep3}>
          <Quiz setQuestionsLoaded={setQuestionsLoaded} t={t} />
        </SumSubKYCWizard.Page>
        <SumSubKYCWizard.Page>
          <SumSubFrame client={client} t={t} />
        </SumSubKYCWizard.Page>
      </SumSubKYCWizard>
    </>
  );
};

export default SumSubVerification;
