import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Regula from 'components/Regula';
import VerificationComponent from 'components/Verification';
import { HOME } from 'constants/routings';
import Modal from 'ui-kit/Modal/Modal';
import { REGULA, SUMSUB, VERIFICATION_TYPES } from 'utils/constant';
import ClientContext from 'utils/contexts/Client';
import UserContext from 'utils/contexts/User';

const Verification = () => {
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const isBalanceEnabled = client?.data?.internalBalanceEnabled;
  const isRegula = isBalanceEnabled;
  const isSumsub = !isBalanceEnabled;

  const getComponentName = () => {
    const random = Math.floor(Math.random() * 100);
    return random < 6 ? REGULA : SUMSUB;
  };

  useEffect(() => {
    const type = localStorage.getItem('verification') || null;
    const isType = type && VERIFICATION_TYPES.includes(type);
    const value = isType ? type : getComponentName();
    localStorage.setItem('verification', value);
  }, []);

  const closeHandler = () => {
    user.refetch();
    setRedirect(true);
  };

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      {isRegula && (
        <Modal
          maxWidth="830px"
          Component={Regula}
          onClose={closeHandler}
          padding="50px 35px"
        />
      )}
      {isSumsub && (
        <Modal
          maxWidth="1266px"
          Component={VerificationComponent}
          onClose={closeHandler}
          padding="50px 35px"
        />
      )}
    </>
  );
};

export default Verification;
