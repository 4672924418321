import React from 'react';
import Tooltip from 'ui-kit/Tooltip';
import { CardsInfoList, CardsInfoItem, CardsInfoBlock } from './styled-ui';

const CardsCommissionInfo = ({ t, commissionInformation, isErip, text }) => {
  const isSellCommission =
    commissionInformation?.sellCommission &&
    commissionInformation?.sellCommission !== '0';

  return (
    <CardsInfoBlock>
      <CardsInfoList>
        {!isErip && (
          <CardsInfoItem>
            {t('profile.cardActions.commissionInformationBuy', {
              value: commissionInformation?.buyCommission,
            })}
          </CardsInfoItem>
        )}
        {isSellCommission && (
          <CardsInfoItem>
            {t('profile.cardActions.commissionInformationSell', {
              value: commissionInformation?.sellCommission,
            })}
          </CardsInfoItem>
        )}
      </CardsInfoList>
      {text && <Tooltip type="INFO" text={text} placement="center" />}
    </CardsInfoBlock>
  );
};

export default CardsCommissionInfo;
