import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { getOldLocale } from 'utils/location';

const LocaleRouter = ({ Component, exact = false, path }) => {
  const RenderRoute = props => {
    const { location } = props;
    const oldLocale = getOldLocale(location);

    if (oldLocale) {
      const pathname = location.pathname.replace(`/${oldLocale}`, '');
      return <Redirect to={{ ...location, pathname }} />;
    }
    return <Component {...props} />;
  };

  return <Route exact={exact} path={path} render={RenderRoute} />;
};

export default LocaleRouter;
