import styled from 'styled-components';
import device from 'device';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  overflow-x: auto;

  @media ${device.laptopL} {
    width: 100%;
  }

  @media ${device.mobile920} {
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

export const Title = styled.div`
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  position: sticky;
  left: 0;
  right: 0;
`;

export const SubTitle = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  margin: 30px 0;

  @media ${device.mobile920} {
    margin: 20px 0;
    font-size: 16px;
  }
`;

export const ReferralContentRow = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  position: sticky;
  left: 0;
  right: 0;

  @media ${device.mobile920} {
    font-size: 16px;
  }
`;

export const WithdrawalBalance = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: sticky;
  left: 0;
  right: 0;

  > div:first-child {
    margin-right: 15px;
  }
`;

export const TableMenu = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: sticky;
  left: 0;
  right: 0;

  button {
    height: 45px;
    padding: 0 15px;
  }
`;

export const ButtonWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '56px'};
  margin: ${({ margin }) => margin || '0 auto'};

  button {
    font-size: 18px;

    @media ${device.mobile920} {
      font-size: 16px;
    }
  }
`;

export const ModalWrapper = styled.div`
  display: grid;
  gap: 30px;
`;

export const ModalTitle = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
`;

export const CheckboxText = styled.span`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  a {
    text-decoration: none;
  }

  @media ${device.laptop} {
    white-space: pre-line;
  }

  @media (max-width: 575px) {
    white-space: normal;
  }
`;

export const WithdrawalDataWrapper = styled.div`
  display: flex;
  align-items: start;
  position: sticky;
  left: 0;
  right: 0;
  margin: 20px 0;
`;

export const ReferralLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  right: 0;

  button {
    background: ${props => props.theme.fullWhite};
  }
`;

export const EditIconButton = styled(EditIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.grayText};
`;

export const EditButton = styled.button`
  padding: 0;
  margin-left: 15px;
  cursor: pointer;
`;
