import React, { useRef, useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { isEmpty } from 'ramda';
import ClientContext from 'utils/contexts/Client';
import UserContext from 'utils/contexts/User';
import { getUserLocale } from 'utils/location';
import { getUserPageNotificationsRequest } from 'utils/services/request/notifications';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import usePersistedState from 'utils/hooks/usePersistedState';
import { DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import { Loader } from 'components/Auth/UserProfile/styled-ui';
import {
  Notification,
  InfoIcon,
  TextPageWrapper,
  Label,
  TextPage,
  Time,
  NotificationPageWrapper,
  NotificationInner,
  EmptyLabel,
  InfoGeneralIcon,
  HeaderInfo,
} from './styled-ui';

const NotificationPage = ({ t }) => {
  const observerNotificationPage = useRef();
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const [notifications, setNotifications] = usePersistedState(
    'notifications',
    []
  );
  const [totalPages, setTotalPages] = usePersistedState(
    'notificationsPages',
    1
  );
  const [notificationPage, setNotificationPage] = useState(0);

  const { data, fetching, loaded, refetch } = useAxiosStateWithRefetch(
    notifications?.length > 0
      ? notifications
      : getUserPageNotificationsRequest({ page: notificationPage })
  );

  const isUserNotHavePhone =
    !user?.data?.phone &&
    client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY &&
    client?.data?.status === 'VERIFIED';

  useEffect(() => {
    if (notificationPage > 0) {
      refetch();
    }
  }, [notificationPage, refetch]);

  useEffect(() => {
    if (data?.content?.length) {
      setNotifications([...notifications, ...data.content]);
      setTotalPages(data.totalPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const lastEventElementRef = node => {
    if (fetching) return;
    if (observerNotificationPage.current) {
      observerNotificationPage.current.disconnect();
    }
    observerNotificationPage.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && notificationPage < totalPages) {
        setNotificationPage(notificationPage + 1);
      }
    });
    if (node) observerNotificationPage.current.observe(node);
  };

  return (
    <NotificationPageWrapper>
      {isUserNotHavePhone && (
        <Notification isPage>
          <NotificationInner>
            <InfoIcon />
            <TextPageWrapper>
              <HeaderInfo>
                <Label isPage>{t('profile.settings.addPersonalData')}</Label>
                <Time isPage>
                  {moment()
                    .locale(getUserLocale())
                    .format('DD MMMM, HH:mm')}
                </Time>
              </HeaderInfo>
              <TextPage>{t('profile.settings.addPhone')}</TextPage>
            </TextPageWrapper>
          </NotificationInner>
        </Notification>
      )}
      {!isEmpty(notifications) &&
        notifications.map(({ id, title, body, creationDate, level }, index) => (
          <Notification
            key={id}
            ref={
              notifications.length === index + 1 ? lastEventElementRef : null
            }
            isPage
          >
            <NotificationInner>
              {level === 'GENERAL' ? (
                <InfoGeneralIcon />
              ) : (
                <InfoIcon level={level} />
              )}
              {level === 'GENERAL' ? (
                <TextPageWrapper>
                  <HeaderInfo>
                    <Label isGeneral isPage level={level}>
                      {body}
                    </Label>
                    <Time isPage>
                      {moment(creationDate)
                        .locale(getUserLocale())
                        .format('DD MMMM, HH:mm')}
                    </Time>
                  </HeaderInfo>
                </TextPageWrapper>
              ) : (
                <TextPageWrapper>
                  <HeaderInfo>
                    <Label isPage level={level}>
                      {title}
                    </Label>
                    <Time isPage>
                      {moment(creationDate)
                        .locale(getUserLocale())
                        .format('DD MMMM, HH:mm')}
                    </Time>
                  </HeaderInfo>
                  <TextPage>{body}</TextPage>
                </TextPageWrapper>
              )}
            </NotificationInner>
          </Notification>
        ))}
      {!notifications.length && loaded && !fetching && !isUserNotHavePhone && (
        <EmptyLabel>{t('notifications.noNotificationData')}</EmptyLabel>
      )}
      {!loaded && fetching && <Loader />}
    </NotificationPageWrapper>
  );
};

export default NotificationPage;
