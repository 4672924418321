import blueTheme from 'components/App/themes/blue';

export const stylesRegulaComponent = {
  fontFamily: 'Inter',
  onboardingScreenStartButtonBackground: blueTheme.blueBg,
  onboardingScreenStartButtonBackgroundHover: blueTheme.blueBg,
  retryScreenRetryButtonBackground: blueTheme.blueBg,
  retryScreenRetryButtonBackgroundHover: blueTheme.blueBg,
  cameraScreenSectorActive: blueTheme.blueBg,
  cameraScreenSectorTarget: blueTheme.btnLightColor,
  cameraScreenStrokeNormal: blueTheme.btnLightColor,
  processingScreenProgress: blueTheme.blueBg,
};

export const getBlob = async image => {
  if (!image) return null;
  const url = `data:image/jpeg;base64,${image}`;
  const res = await fetch(url);
  const blob = await res.blob();
  return blob;
};

export const getRegulaFormData = async images => {
  const formData = new FormData();
  const blobs = [
    await getBlob(images.image1, 1),
    await getBlob(images.image2, 2),
    await getBlob(images.image3, 3),
  ];
  blobs.forEach((blob, index) => {
    if (blob) {
      const fileName = `image${index + 1}`;
      formData.append(fileName, blob, `${fileName}.jpeg`);
    }
  });
  return formData;
};
