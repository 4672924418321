import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { defineComponents } from '@regulaforensics/vp-frontend-document-components';
import ReactFlagsSelect from 'react-flags-select';
import { isEmpty } from 'ramda';
import {
  DOCUMENT_SDK_ACTIONS,
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_CAMEL,
  REGULA_PARSING_ERRORS,
} from 'utils/constants/regula';
import { COUNTRY_CODES, DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import observerWC from 'utils/webComponents';
import { processDocumentsUrl } from 'utils/services/request/verifications';
import DocumentDetails from 'components/Regula/DocumentDetails';
import {
  getRegulaFormData,
  stylesRegulaComponent,
} from 'components/Regula/utils';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import passportCorrect from 'assets/images/verification/i_passport_correct.e3e8efdd.png';
import passportIncorrect from 'assets/images/verification/i_passport_incorrect_1.293168d3.png';
import passportIncorrect2 from 'assets/images/verification/i_passport_incorrect_2.02c0839f.png';
import idCardCorrect from 'assets/images/verification/i_idcard_correct.e6412a46.png';
import idCardIncorrect from 'assets/images/verification/i_idcard_incorrect_1.42c3b612.png';
import idCardIncorrect2 from 'assets/images/verification/i_idcard_incorrect_2.c13c1bd7.png';
import validationCameraSnapshot from './validation';
import {
  ButtonsContainer,
  CameraSnapshotWrapper,
  DeleteIcon,
  FlagsWrapper,
  FormButton,
  InstructionImages,
  Message,
  RadioInput,
  SnapshotWrapper,
  Thumb,
  ThumbImage,
  ThumbsButton,
  ThumbsContainer,
  ThumbWrapper,
  TitleContainer,
  TypesDocWrapper,
} from './styled-ui';

const docTypes = [
  DOCUMENT_TYPES.PASSPORT,
  DOCUMENT_TYPES.RESIDENT_CARD,
  DOCUMENT_TYPES.ID_CARD,
];

const CameraSnapshot = ({
  handleOnSubmitComplete,
  handleLoader,
  reuploadData,
  isReupload,
  images,
  setImages,
}) => {
  const { t } = useTranslation();
  const isChangeDocs = reuploadData?.identityDocument;
  const isChangeReg = reuploadData?.proofOfAddress;
  const isChangeOnlyReg = !isChangeDocs && isChangeReg;
  const [isComponentOpen, setIsComponentOpen] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [isDocParseSuccess, setIsDocParseSuccess] = useState(false);
  const [docCountryCode, setDocCountryCode] = useState(COUNTRY_CODES.BY);
  const [docType, setDocType] = useState(
    isReupload ? DOCUMENT_TYPES.REUPLOAD : DOCUMENT_TYPES.PASSPORT
  );
  const [errors, setErrors] = useState({});
  const [notifications, setNotifications] = useState({});
  const [currentImage, setCurrentImage] = useState('');
  const [parsedFields, setParsedFields] = useState(null);
  const [countThumbs, setCountThumbs] = useState(3);
  const elementRef = useRef();
  const containerRef = useRef();

  const instructionImagesPassport = [
    passportCorrect,
    passportIncorrect,
    passportIncorrect2,
  ];
  const instructionImagesCard = [
    idCardCorrect,
    idCardIncorrect,
    idCardIncorrect2,
  ];

  const buttons = {
    passport: {
      1: t('verification.regula.documents.passport.firstPage'),
      2: t('verification.regula.documents.passport.secondPage'),
      3: t('verification.regula.documents.passport.thirdPage'),
    },
    residentCard: {
      1: t('verification.regula.documents.residentCard.firstPage'),
      2: t('verification.regula.documents.residentCard.secondPage'),
      3: t('verification.regula.documents.residentCard.thirdPage'),
    },
    idCard: {
      1: t('verification.regula.documents.idCard.firstPage'),
      2: t('verification.regula.documents.idCard.secondPage'),
      3: t('verification.regula.documents.idCard.thirdPage'),
    },
    reupload: {
      1: t('verification.regula.documents.reupload.firstPage'),
      2: t('verification.regula.documents.reupload.secondPage'),
      3: t('verification.regula.documents.reupload.thirdPage'),
    },
  };

  const listener = useCallback(
    ({ detail: { action, data } }) => {
      if (action === DOCUMENT_SDK_ACTIONS.PROCESS_FINISHED) {
        const status = data?.status;
        const isFinishStatus = status === 1;
        if (!isFinishStatus || !data?.response) return;
        const isExpired = JSON.parse(localStorage.getItem('imageExpired'));
        if (isExpired) {
          setErrors({
            message: t('verification.regula.documents.imageExpired'),
          });
        } else {
          const image = data.response[0].raw;
          setImages({ ...images, [currentImage]: image });
          setErrors({});
          setNotifications({});
        }
        localStorage.setItem('imageExpired', JSON.stringify(false));
        setIsComponentOpen(false);
      }
      if (action === DOCUMENT_SDK_ACTIONS.CLOSE) {
        setIsComponentOpen(false);
      }
    },
    [images, currentImage, setErrors, setImages, setIsComponentOpen, t]
  );

  useEffect(() => {
    const isErrors = !isEmpty(errors);
    if (isChangeDocs && isChangeReg) {
      setIsSendButtonDisabled(
        !images.image1 || !images.image2 || !images.image3 || isErrors
      );
    } else if (isChangeReg) {
      setIsSendButtonDisabled(!images.image1 || isErrors);
      setCountThumbs(1);
    } else if (isChangeDocs) {
      setIsSendButtonDisabled(!images.image1 || !images.image2 || isErrors);
      setCountThumbs(2);
    } else {
      setIsSendButtonDisabled(
        !images.image1 || !images.image2 || !images.image3 || isErrors
      );
    }
  }, [images, isChangeReg, isChangeDocs, isDocParseSuccess]);

  useEffect(() => {
    const containerCurrent = containerRef.current;
    defineComponents();
    if (!containerCurrent) return;
    containerCurrent.addEventListener('camera-snapshot', listener);
    // eslint-disable-next-line consistent-return
    return () => {
      containerCurrent.removeEventListener('camera-snapshot', listener);
    };
  }, [currentImage, images, listener]);

  useEffect(() => {
    const elementRefCurrent = elementRef.current;
    if (!elementRefCurrent) return;
    if (elementRefCurrent.shadowRoot) {
      observerWC.observe(elementRefCurrent.shadowRoot, {
        childList: true,
        subtree: true,
      });
    }
    elementRefCurrent.settings = {
      locale: 'ru',
      startScreen: true,
      multipleFileInput: false,
      changeCameraButton: true,
      statusIcon: true,
      closeDisabled: true,
      customization: stylesRegulaComponent,
    };
  }, [isComponentOpen]);

  const getErrorMessage = error => {
    switch (error) {
      case REGULA_PARSING_ERRORS.PROCESSING:
        return t('verification.documentsProcess.processingError');
      case REGULA_PARSING_ERRORS.ADULT:
        return t('verification.documentsProcess.adultError');
      default:
        return t('verification.documentsProcess.unknownError');
    }
  };

  useEffect(() => {
    const isSuccess = isEmpty(errors) && notifications.success;
    setIsDocParseSuccess(isSuccess);
  }, [errors, notifications]);

  const sendHandler = async () => {
    setErrors({});
    setNotifications({});
    const params = {
      docCountryCode:
        docCountryCode === COUNTRY_CODES.BY
          ? DIGITAL_COUNTRY_CODES.BY
          : docCountryCode,
      docType,
    };
    const formData = await getRegulaFormData(images);
    if (handleLoader) {
      handleLoader(true);
    }
    axios
      .post(processDocumentsUrl, formData, {
        params,
      })
      .then(response => {
        if (response.status === 200) {
          validationCameraSnapshot(
            response?.data,
            t,
            setErrors,
            setNotifications,
            isChangeOnlyReg
          );
          setParsedFields(response?.data);
        }
      })
      .catch(e => {
        setErrors({
          message: getErrorMessage(e.response?.data?.message),
        });
        setIsSendButtonDisabled(true);
      })
      .finally(() => {
        if (handleLoader) {
          handleLoader(false);
        }
      });
  };

  const deleteImageHandler = name =>
    setImages(prevState => ({ ...prevState, [name]: null }));

  const refreshHandler = () => {
    setImages([]);
    setIsDocParseSuccess(false);
    setIsSendButtonDisabled(true);
    setParsedFields(null);
    setErrors({});
    setNotifications({});
  };

  const printInstructionImages = array =>
    array.map((item, index) => (
      <img src={item} alt={`image_${index}`} key={index} />
    ));

  const addImageHandler = name => {
    setCurrentImage(name);
    setIsComponentOpen(true);
  };

  const changeDoctypeHandler = e => {
    setDocType(e.target.value);
    setImages({});
  };

  const renderDocTypesSelection = () =>
    docTypes.map(type => (
      <RadioInput key={type}>
        <input
          type="radio"
          id={type}
          name="docType"
          value={type}
          checked={docType === type}
          onChange={changeDoctypeHandler}
          disabled={isReupload}
        />
        <label htmlFor={type}>
          {t(`verification.documentTypes.${DOCUMENT_TYPES_CAMEL[type]}`)}
        </label>
      </RadioInput>
    ));

  const thumbsButton = index => {
    const typeDocCamelCase = DOCUMENT_TYPES_CAMEL[docType];
    const textButton = buttons[typeDocCamelCase];
    return (
      <ThumbsButton
        onClick={() => addImageHandler(`image${index}`)}
        key={index}
      >
        <UploadIcon />
        {isChangeReg && !isChangeDocs
          ? t('verification.regula.modes.changeRegTitle')
          : textButton[index]}
      </ThumbsButton>
    );
  };

  const getMessagesByType = (values, type) =>
    Object.entries(values).map(([key, value]) => (
      <Message type={type} key={key}>
        {value}
      </Message>
    ));

  const renderThumbs = () =>
    Array.from({ length: countThumbs }).map((_, index) => {
      const imageKey = `image${index + 1}`;
      const imageData = images[imageKey];

      return imageData ? (
        <ThumbWrapper key={index + 1}>
          <Thumb>
            {!isDocParseSuccess && (
              <DeleteIcon onClick={() => deleteImageHandler(imageKey)} />
            )}
            <ThumbImage
              src={`data:image/jpeg;base64,${imageData}`}
              alt={`preview_image_${index + 1}`}
            />
          </Thumb>
        </ThumbWrapper>
      ) : (
        thumbsButton(index + 1)
      );
    });

  return (
    <SnapshotWrapper>
      {!isReupload && (
        <>
          <span>{t('verification.regula.documents.title')}</span>
          <FlagsWrapper>
            <span>{t('verification.regula.documents.label')}</span>
            <ReactFlagsSelect
              selected={docCountryCode}
              onSelect={setDocCountryCode}
            />
          </FlagsWrapper>
          <div>{t('verification.chooseDocumentType')}</div>
        </>
      )}
      <TypesDocWrapper>{renderDocTypesSelection()}</TypesDocWrapper>
      <InstructionImages>
        {printInstructionImages(
          docType === DOCUMENT_TYPES.PASSPORT
            ? instructionImagesPassport
            : instructionImagesCard
        )}
      </InstructionImages>
      <TitleContainer>
        <p>
          {t(
            `verification.regula.documents.${DOCUMENT_TYPES_CAMEL[docType]}.title`
          )}
        </p>
        <p>
          <Trans i18nKey="verification.regula.documents.condition" t={t} />
        </p>
        <p>
          {t(
            `verification.regula.documents.${DOCUMENT_TYPES_CAMEL[docType]}.description`
          )}
        </p>
        <p>
          {isChangeReg && (
            <Trans
              i18nKey="verification.regula.documents.changeRegistration"
              t={t}
            />
          )}
        </p>
      </TitleContainer>
      <ThumbsContainer>{renderThumbs()}</ThumbsContainer>
      {errors && getMessagesByType(errors, 'error')}
      {isEmpty(errors) && notifications && getMessagesByType(notifications)}
      <ButtonsContainer>
        {(!isEmpty(errors) || isDocParseSuccess) && (
          <FormButton onClick={refreshHandler}>
            {t('verification.refreshButton')}
          </FormButton>
        )}
        {isDocParseSuccess && !isReupload && (
          <FormButton onClick={handleOnSubmitComplete}>
            {t('verification.doneButton')}
          </FormButton>
        )}
        {!isDocParseSuccess && !isReupload && (
          <FormButton onClick={sendHandler} disabled={isSendButtonDisabled}>
            {t('verification.sendButton')}
          </FormButton>
        )}
        {isReupload && (
          <FormButton
            onClick={handleOnSubmitComplete}
            disabled={isSendButtonDisabled}
          >
            {t('verification.sendButton')}
          </FormButton>
        )}
      </ButtonsContainer>
      <DocumentDetails fields={parsedFields} />
      <div ref={containerRef}>
        {isComponentOpen && (
          <fullscreen-container>
            <CameraSnapshotWrapper>
              <camera-snapshot ref={elementRef} />
            </CameraSnapshotWrapper>
          </fullscreen-container>
        )}
      </div>
    </SnapshotWrapper>
  );
};

export default CameraSnapshot;
