/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  Suspense,
  lazy,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { pipe, last, propOr, pathOr, split, pathEq } from 'ramda';
import wsConnectInstance from 'ws-connect';
import { getSystemRequisitesForClient } from 'utils/services/request/card';
import { fileDownload } from 'utils/fileDownload';
import {
  currencies,
  CONFIRMED,
  DECLINED,
  EXPIRED,
  PROCESSING,
  REJECTED,
  REQUEST,
  CHANGED,
  PAUSED,
} from 'utils/constant';
import {
  useAxiosStateWithRefetch,
  useAxiosStateWithRefetchNoFirstCall,
} from 'utils/hooks/axiosHook';
import { DateTime } from 'luxon';
import {
  getBlockchainFeeValueOrder,
  getServiceFeeValueOrder,
} from 'utils/fees';
import { getFiatCurrencyFromPair, isCrypto } from 'utils/crypto';
import {
  PROVIDER_TYPES,
  SETTLEMENT_ACCOUNT,
} from 'utils/constants/paymentMethods';
import OPERATION_TYPES from 'utils/constants/operationTypes';
import { ORDER_PHASES } from 'utils/constants/orderPhases';
import {
  EndOrderButton,
  CancelOrderButton,
  ContinueOrderButton,
  DownloadButton,
} from 'ui-kit/Button';
import Spinner from 'ui-kit/Spinner';
import CountDown from 'ui-kit/CountDown';
import ExchangeOperationStatus from 'components/ExchangeOperationStatus';
import { HOME } from 'constants/routings';

import {
  ExchangeOperationDate,
  ExchangeOperationNumber,
} from 'components/ExchangeOperationStatus/styled-ui';

import {
  ProcessingGrid,
  StyledProcessingStatus,
  StyledRefreshWhiteIcon,
  Submit,
  UpdateTextButton,
  Wrapper,
  WrapperLink,
  InfoRequestWrapper,
  ProcessingWrapper,
  BlockTitle,
  BlockTitleUpdatedTerms,
  ErrorLabel,
  SystemErrorLabel,
  ChangedOrderWarning,
  ActionButtonWrapper,
  OrderTimerChangeApprove,
  LowTextInfo,
  EmptyBlock,
  DownloadIcon,
  BankAccountValue,
  WrapperBankAccount,
  SubmitWrapper,
} from './styled-ui';

const Statuses = lazy(() => import('components/ProcessingStatus/statuses'));

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'unMount': {
      if (
        wsConnectInstance.wsConnection.connected &&
        state &&
        state.unsubscribe
      ) {
        state.unsubscribe();
      }
      break;
    }
    default:
  }
}

const ProcessingStatus = ({
  data,
  refetch,
  isOrderUpdated,
  fetching: orderFetching,
  user,
}) => {
  const exchangeOperationStatuses = useAxiosStateWithRefetch({
    method: 'get',
    url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/current/phases`,
  });

  const { t } = useTranslation();
  const [wsSubscription, dispatch] = useReducer(reducer, null);
  const [additionalSub, setAdditionalSub] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loadingApproveRequest, setLoadingApproveRequest] = useState(false);
  const [loadingNewOperation, setLoadingNewOperation] = useState(false);
  const [isOrderChangedContinue, setIsOrderChangedContinue] = useState(false);
  const [exchangeOperationStatusesData, setExchangeOperationData] = useState(
    null
  );

  const isApproveDisabled = disabled || data.unprocessableStatus;
  const isCancelDisabled =
    loadingApproveRequest ||
    (data.unprocessableStatus &&
      !['OPERATION_DISABLED', 'OPERATION_DAY_DISABLED'].includes(
        data.unprocessableStatus
      ));

  const updateOrderStatus = useCallback(exchangeOperationStatuses.refetch, []);

  const updateOrder = useCallback(refetch, []);
  const updateStatus = () => {
    if (exchangeOperationStatuses.fetching) return;
    updateOrderStatus();
  };

  const approveOrderOparation = useAxiosStateWithRefetchNoFirstCall(
    {
      method: 'post',
      url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/approve`,
    },
    []
  );

  useEffect(() => {
    setLoadingApproveRequest(approveOrderOparation.fetching);
    if (approveOrderOparation.data) {
      setIsOrderChangedContinue(true);
      updateOrder();
      updateOrderStatus();
    }
    if (
      approveOrderOparation.error &&
      approveOrderOparation.error.status === 'INVALID_ACTUAL_RATE'
    ) {
      updateOrder();
    }
  }, [
    approveOrderOparation.data,
    approveOrderOparation.fetching,
    approveOrderOparation.error,
  ]);

  const chosenCorrectStatusRequest = exchangeOperationStatuses.data
    ? exchangeOperationStatuses.data.status
    : data.status;

  const statuses = exchangeOperationStatusesData?.phases.map(
    phase => phase.title
  );
  const isFiatToCryptoProcessing =
    data?.operationType === OPERATION_TYPES.FIAT_TO_CRYPTO &&
    data?.fiatTransaction?.providerType === SETTLEMENT_ACCOUNT &&
    statuses?.includes(ORDER_PHASES.FIAT_PROCESSING);

  const isCryptoToFiatPending =
    data?.operationType === OPERATION_TYPES.CRYPTO_TO_FIAT &&
    data?.fiatTransaction?.providerType === SETTLEMENT_ACCOUNT &&
    !data?.additionalSubmitTimeout &&
    statuses?.includes(ORDER_PHASES.CRYPT_PENDING);

  const isCryptoToFiatDelayed =
    data?.operationType === OPERATION_TYPES.CRYPTO_TO_FIAT &&
    data?.additionalSubmitTimeout &&
    statuses?.includes(ORDER_PHASES.CRYPT_PENDING);

  const isCounterOffer =
    chosenCorrectStatusRequest !== CHANGED && !isOrderChangedContinue;

  const isSettlementOrTimeoutCondition =
    (data?.fiatTransaction?.providerType === SETTLEMENT_ACCOUNT ||
      data?.additionalSubmitTimeout) &&
    isCounterOffer &&
    !isFiatToCryptoProcessing &&
    !isCryptoToFiatPending &&
    !isCryptoToFiatDelayed;

  const canceledSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_EXCHANGE_URL}/orders/${
          isSettlementOrTimeoutCondition ? 'reject-processing' : 'rejection'
        }`
      );
      refetch();
      updateOrderStatus();
    } catch (e) {
      if (e.response && e.response.data.status === 'NOT_FOUND') {
        refetch();
        updateOrderStatus();
      }
    }
  };
  const canceledSubmitHandler = useCallback(() => {
    setDisabled(true);
    canceledSubmit();
  }, [refetch, isSettlementOrTimeoutCondition]);

  const handleSocketComing = message => {
    if (message.body) {
      const messageBody = JSON.parse(message.body);
      if (messageBody.type === 'ORDER_STATUS_CHANGED') {
        setTimeout(updateOrderStatus, 3000);
      }
    }
  };

  const renderSystemError = systemOrderStatusUnprocessable => {
    switch (systemOrderStatusUnprocessable) {
      case 'OPERATION_DISABLED':
        return t('pending.stoppedOperation');
      case 'OPERATION_DAY_DISABLED':
        return t('pending.tempStopped');
      default:
        return t('pending.stoppedAllOperation');
    }
  };

  const renderInfoText = text => {
    switch (text) {
      case 'CHANGED_BY_INVALID_AMOUNT':
        return t('pending.recieveAnotherSum');
      case 'CHANGED_BY_TIMEOUT':
        return t('pending.notRecieve');
      default:
        return '';
    }
  };

  useEffect(() => {
    if (wsConnectInstance.wsConnection.connected && !wsSubscription) {
      const subscription = wsConnectInstance.wsConnection.subscribe(
        '/user/notifications/orders/events',
        handleSocketComing
      );
      dispatch({ type: 'set', payload: subscription });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsConnectInstance.wsConnection.connected, additionalSub]);

  useEffect(() => {
    setTimeout(() => setAdditionalSub(true), 1000);
    return () => dispatch({ type: 'unMount' });
  }, []);

  const handleNewOperation = async () => {
    setLoadingNewOperation(true);
    try {
      await axios.delete(`${process.env.REACT_APP_EXCHANGE_URL}/orders`);
      await refetch();
      await user.refetch();
    } catch (e) {
      await refetch();
      await user.refetch();
    }
  };

  useEffect(() => {
    if (exchangeOperationStatuses.loaded) {
      setExchangeOperationData(exchangeOperationStatuses.data);
      if (exchangeOperationStatuses.error) {
        if (exchangeOperationStatuses.error.status === 'NOT_FOUND') {
          setRedirect(true);
        }
      }
    }
    if (
      exchangeOperationStatuses.data &&
      [CONFIRMED, DECLINED, CHANGED].includes(
        exchangeOperationStatuses.data.status
      ) &&
      !exchangeOperationStatuses.fetching
    ) {
      updateOrder();
    }
    const phases = propOr([], 'phases', exchangeOperationStatuses.data);
    if (phases.length === 1) {
      updateOrder();
    }
  }, [
    exchangeOperationStatuses.data,
    exchangeOperationStatuses.loaded,
    refetch,
  ]);

  const {
    creationDate,
    exchangeOperation: {
      currencyPair,
      outputAsset,
      plainRatio: ratio,
      inputAsset,
    },
    cryptoTransaction,
    number,
    expiresAt,
    serverDate,
    promoCode,
  } = data;

  const [downloadIsFetching, setDownloadIsFetching] = useState(false);

  const downloadSystemRequisites = async () => {
    try {
      setDownloadIsFetching(true);
      const requisite = await getSystemRequisitesForClient();
      const fileName = pipe(
        pathOr('', ['headers', 'content-disposition']),
        split('='),
        last,
        fileN =>
          fileN.includes('whitebird_order_invoice_') ? fileN : 'requisite.pdf'
      )(requisite);
      setDownloadIsFetching(false);
      fileDownload(fileName, requisite.data);
    } catch (e) {
      setDownloadIsFetching(false);
    }
  };

  const getTimeLeftOnChangedOrder = (dateNow, expireDate) => {
    if (!dateNow || !moment(dateNow).isValid()) return null;
    if (!expireDate || !moment(expireDate).isValid()) return null;

    const expiredAtIso = DateTime.fromISO(expireDate);
    const serverDateIso = DateTime.fromISO(dateNow);

    const difference = expiredAtIso.diff(serverDateIso, 'seconds');
    return difference && difference.values && difference.values.seconds;
  };

  const cryptoBuy = isCrypto(currencyPair.fromCurrency);

  const operationDateLuxon = DateTime.fromISO(creationDate).toFormat(
    'dd.MM.yyyy HH:mm:ss'
  );

  useEffect(() => {
    const orderChangedRequestInterval = setInterval(() => {
      if (orderFetching) return;
      if (chosenCorrectStatusRequest !== CHANGED) return;
      refetch();
    }, 20000);
    return () => {
      clearInterval(orderChangedRequestInterval);
    };
  }, [refetch, chosenCorrectStatusRequest]);

  const lastPhase = pipe(
    propOr([], 'phases'),
    last,
    propOr('', 'title')
  )(exchangeOperationStatusesData);

  const fiatCurrencyFromPair = getFiatCurrencyFromPair(currencyPair);

  const isSettlementOrder = pathEq(
    ['fiatTransaction', 'providerType'],
    PROVIDER_TYPES.SETTLEMENT,
    data
  );

  const isEripOrder = pathEq(
    ['fiatTransaction', 'providerType'],
    PROVIDER_TYPES.ERIP,
    data
  );

  const provider = pathOr(null, ['fiatTransaction', 'providerType'], data);

  const serviceFee = getServiceFeeValueOrder(
    data.previousOrder || data,
    fiatCurrencyFromPair
  );

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      <ProcessingWrapper>
        <ProcessingGrid extend={cryptoBuy}>
          <InfoRequestWrapper>
            <ExchangeOperationNumber>
              {t('pending.exchangeNumber', { number })}
            </ExchangeOperationNumber>
            <ExchangeOperationDate>
              {t('pending.createdAt', { operationDateLuxon })}
            </ExchangeOperationDate>
            {chosenCorrectStatusRequest === CHANGED && !isOrderChangedContinue && (
              <>
                {getTimeLeftOnChangedOrder(serverDate, expiresAt) && (
                  <OrderTimerChangeApprove>
                    {t('pending.cancelAfter')}{' '}
                    <CountDown
                      initialTimeLeft={getTimeLeftOnChangedOrder(
                        serverDate,
                        expiresAt
                      )}
                    />
                  </OrderTimerChangeApprove>
                )}
              </>
            )}
          </InfoRequestWrapper>
          <ExchangeOperationStatus
            operationNumber={number}
            isOrderUpdated={isOrderUpdated}
            status={chosenCorrectStatusRequest}
            operationDate={creationDate}
            currencyPair={currencyPair}
            calculation={
              data.previousOrder
                ? data.previousOrder.exchangeOperation
                : { inputAsset, outputAsset }
            }
            paymentTokenId={data.fiatTransaction.paymentToken}
            provider={provider}
            ratio={
              data.previousOrder
                ? data.previousOrder.exchangeOperation?.plainRatio
                : ratio
            }
            address={cryptoTransaction?.toAddress}
            blockchainFee={`${getBlockchainFeeValueOrder(
              data.previousOrder || data
            )} ${fiatCurrencyFromPair}`}
            serviceFee={serviceFee}
            feePaymentEnabledByClient={
              cryptoTransaction.feePaymentEnabledByClient
            }
            exchangeOperationStatusesData={exchangeOperationStatusesData}
            promoCode={promoCode}
            isSettlementOrder={isSettlementOrder}
            isEripOrder={isEripOrder}
            eripNumber={data?.fiatTransaction?.internalToken}
          />
          {chosenCorrectStatusRequest === CHANGED && !isOrderChangedContinue && (
            <>
              <ChangedOrderWarning>
                <BlockTitleUpdatedTerms color="#0069ff">
                  {t('pending.termsUpdated')}
                </BlockTitleUpdatedTerms>
                {renderInfoText(lastPhase)}
              </ChangedOrderWarning>
              <BlockTitle>{t('pending.newTerms')}</BlockTitle>
              <ExchangeOperationStatus
                bgColor="#f1f7ff"
                isNext
                isSettlementOrder={isSettlementOrder}
                operationNumber={number}
                isOrderUpdated={isOrderUpdated}
                status={chosenCorrectStatusRequest}
                operationDate={creationDate}
                currencyPair={currencyPair}
                calculation={{ inputAsset, outputAsset }}
                paymentTokenId={data?.fiatTransaction?.paymentToken}
                provider={provider}
                ratio={ratio}
                address={cryptoTransaction?.toAddress}
                blockchainFee={`${getBlockchainFeeValueOrder(
                  data
                )} ${fiatCurrencyFromPair}`}
                serviceFee={serviceFee}
                feePaymentEnabledByClient={
                  cryptoTransaction.feePaymentEnabledByClient
                }
                promoCode={promoCode}
                isEripOrder={isEripOrder}
                eripNumber={data?.fiatTransaction?.internalToken}
              />
              <LowTextInfo>{t('pending.labelIfCancel')}</LowTextInfo>
              <ActionButtonWrapper>
                <CancelOrderButton
                  orderInRow
                  lowMargin
                  disabled={isCancelDisabled}
                  onClick={canceledSubmitHandler}
                >
                  {disabled ? <Spinner /> : t('pending.cancelOrder')}
                </CancelOrderButton>

                <ContinueOrderButton
                  lowMargin
                  onClick={approveOrderOparation.fetch}
                  disabled={isApproveDisabled}
                >
                  {loadingApproveRequest ? (
                    <Spinner />
                  ) : (
                    t('pending.submitOrder')
                  )}
                </ContinueOrderButton>
              </ActionButtonWrapper>
              {data.unprocessableStatus && (
                <SystemErrorLabel>
                  {renderSystemError(data.unprocessableStatus)}
                </SystemErrorLabel>
              )}
              {approveOrderOparation.error &&
                approveOrderOparation.error.status ===
                  'INVALID_ACTUAL_RATE' && (
                  <SystemErrorLabel>
                    {t('pending.ratesWasUpdated')}
                  </SystemErrorLabel>
                )}
              {approveOrderOparation.error &&
                approveOrderOparation.error.status !==
                  'INVALID_ACTUAL_RATE' && (
                  <ErrorLabel>{t('pending.somethingWentWrong')}</ErrorLabel>
                )}
            </>
          )}
          {isSettlementOrder &&
            chosenCorrectStatusRequest === PROCESSING &&
            lastPhase === 'USER_CONFIRMATION' &&
            !cryptoBuy && (
              <>
                <BlockTitle>{t('bankAccountViewer.invoice')}</BlockTitle>
                <WrapperBankAccount>
                  <Wrapper>
                    <DownloadButton
                      disabled={downloadIsFetching}
                      onClick={downloadSystemRequisites}
                    >
                      {downloadIsFetching ? (
                        <Spinner />
                      ) : (
                        <>
                          <DownloadIcon />
                          <BankAccountValue>
                            {t('bankAccountViewer.uploadBankAccountRequisites')}
                          </BankAccountValue>
                        </>
                      )}
                    </DownloadButton>
                  </Wrapper>
                </WrapperBankAccount>
              </>
            )}

          <StyledProcessingStatus noMargin>
            <BlockTitle>{t('pending.titleStatus')}</BlockTitle>
          </StyledProcessingStatus>

          <Suspense fallback={<Spinner />}>
            <Statuses
              statuses={exchangeOperationStatusesData}
              resultMessage={data?.fiatTransaction?.resultMessage}
              cryptoBuy={cryptoBuy}
            />
          </Suspense>
          <EmptyBlock />

          <Submit>
            {[PROCESSING, CHANGED, PAUSED].includes(
              chosenCorrectStatusRequest
            ) ? (
              <SubmitWrapper noCursor>
                {isSettlementOrTimeoutCondition && (
                  <CancelOrderButton
                    disabled={isCancelDisabled}
                    onClick={canceledSubmitHandler}
                  >
                    {disabled ? <Spinner /> : t('pending.cancelOrder')}
                  </CancelOrderButton>
                )}
                <EndOrderButton onClick={updateStatus}>
                  <StyledRefreshWhiteIcon
                    fetching={exchangeOperationStatuses.fetching.toString()}
                  />
                  <UpdateTextButton>
                    {t('pending.updateStatus')}
                  </UpdateTextButton>
                </EndOrderButton>
              </SubmitWrapper>
            ) : (
              <WrapperLink to="#">
                <EndOrderButton
                  onClick={handleNewOperation}
                  disabled={loadingNewOperation}
                >
                  {loadingNewOperation ? <Spinner /> : t('pending.newExchange')}
                </EndOrderButton>
              </WrapperLink>
            )}
          </Submit>
        </ProcessingGrid>
      </ProcessingWrapper>
    </>
  );
};

ProcessingStatus.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.oneOf([
      REQUEST,
      PROCESSING,
      CONFIRMED,
      REJECTED,
      EXPIRED,
      DECLINED,
      CHANGED,
      PAUSED,
    ]).isRequired,
    modificationDate: PropTypes.string.isRequired,
    exchangeOperation: PropTypes.shape({
      currencyPair: PropTypes.shape({
        fromCurrency: PropTypes.oneOf(currencies).isRequired,
        toCurrency: PropTypes.oneOf(currencies).isRequired,
      }).isRequired,
      ratio: PropTypes.number.isRequired,
      inputAsset: PropTypes.number.isRequired,
      outputAsset: PropTypes.number.isRequired,
    }).isRequired,
    cryptoTransaction: PropTypes.shape({
      transactionHash: PropTypes.string,
      toAddress: PropTypes.string,
      status: PropTypes.string,
    }).isRequired,
    fiatTransaction: PropTypes.shape({
      status: PropTypes.string,
      card: PropTypes.shape({
        id: PropTypes.string.isRequired,
        maskedPan: PropTypes.string.isRequired,
        expiryDate: PropTypes.string.isRequired,
        cardHolderName: PropTypes.string.isRequired,
      }),
    }),
    creationDate: PropTypes.string.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ProcessingStatus;
