import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import qs from 'qs';
import UserContext from 'utils/contexts/User';
import { logout } from 'utils/services/request/auth';
import NotFoundLayout from 'components/Layouts/404';
import NoMatch from 'pages/NoMatch/index';
import Verification from 'pages/Verifications';
import { HOME, LOGOUT, SIGN_IN, VERIFICATIONS } from 'constants/routings';

const PrivateRoute = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const queryReuploadVerification = queryParams?.reupload;

  if (!user.data) {
    const redirectUrl = queryReuploadVerification
      ? `${SIGN_IN}?redirectPage=${VERIFICATIONS}&reupload=${queryReuploadVerification}`
      : HOME;
    history.push(redirectUrl);
  }

  return (
    <>
      <Switch>
        <Route exact path={VERIFICATIONS} component={Verification} />
        <Route exact path={LOGOUT} component={logout} />
        <NotFoundLayout>
          <Route component={NoMatch} />
        </NotFoundLayout>
      </Switch>
    </>
  );
};

export default PrivateRoute;
