import styled from 'styled-components';
import device from 'device';
import { Link } from 'react-router-dom';
import { ReactComponent as Mastercard } from 'assets/images/cards/masterCard.svg';
import { ReactComponent as Visa } from 'assets/images/cards/visa.svg';
import { ReactComponent as SettlementSvg } from 'assets/images/payments/settlement.svg';
import { ReactComponent as EripSvg } from 'assets/images/payments/erip.svg';
import { ReactComponent as ChoosenMarkIcon } from 'assets/images/icons/choosen-select-option.svg';
import { ReactComponent as AssistSvg } from 'assets/images/payments/assist.svg';
import { ReactComponent as GazpromSvg } from 'assets/images/payments/gazprom.svg';
import { ReactComponent as BelCardSvg } from 'assets/images/cards/belcard.svg';
import { ReactComponent as MirSvg } from 'assets/images/cards/mir.svg';
import { ReactComponent as Disabled } from 'assets/images/cards/disabled.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 46px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.grayBg};
  &:hover {
    background-color: ${({ theme }) => theme.emptySelectHoverBackground};
  }
  margin-bottom: 10px;
`;

export const SelectWrapper = styled.div`
  height: 46px;
  margin-bottom: 10px;
`;
export const AssistIcon = styled(AssistSvg)`
  margin-left: 10px;
  width: 30px;
  height: 30px;
`;
export const GazpromIcon = styled(GazpromSvg)`
  margin-left: 10px;
  width: 30px;
  height: 30px;
`;

export const MastercardIcon = styled(Mastercard)`
  margin-right: 10px;
  width: 36px;
  zoom: 1.2;

  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;
export const VisaIcon = styled(Visa)`
  margin-right: 10px;
  width: 36px;
  zoom: 1.2;
  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;

export const BelCardIcon = styled(BelCardSvg)`
  margin-right: 10px;
  width: 36px;
  zoom: 1.2;
  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;
export const MirIcon = styled(MirSvg)`
  margin-right: 10px;
  width: 36px;
  zoom: 1.2;
  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;

export const SettlementIcon = styled(SettlementSvg)`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  zoom: 1.2;
  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;

export const EripIcon = styled(EripSvg)`
  margin-right: 10px;
  width: 52px;
  height: 18px;
  zoom: 1.2;

  @media ${device.mobile920} {
    zoom: 1;
    margin-right: 10px;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'initial' : 'pointer')};

  &:hover {
    background: ${({ isDisabled }) => (isDisabled ? 'none' : '#F6F6F6')};
  }
  @media (max-width: 1150px) {
    padding: 5px;
  }
  @media ${device.mobile920} {
    padding: 5px 10px;
  }
`;

export const SelectOptionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  min-height: 36px;

  @media (max-width: 1150px) {
    gap: 15px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const SelectOptionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.94rem;
  letter-spacing: 0.001em;
  color: ${({ isDisabled }) => isDisabled && '#C6C6C6'};
  line-height: 18px;

  svg,
  g {
    path,
    polygon,
    rect {
      fill: ${({ isIconDisabled }) => isIconDisabled && '#C6C6C6'} !important;
    }
  }

  @media (max-width: 1150px) {
    font-size: 15px;
  }
`;

export const CardNotAvailable = styled.div`
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.notificatonErrorColor};
  white-space: pre;
  margin-left: auto;
  text-align: right;
  margin-right: ${({ isSelectedCard }) => (isSelectedCard ? '15px' : '0')};
  > svg {
    display: none;
  }
  @media (max-width: 1150px) {
    font-size: 12px;
    white-space: nowrap;
    margin-left: 0;

    > span {
      display: none;
    }
    > svg {
      display: inline-block;
    }
  }
`;

export const DisabledIcon = styled(Disabled)`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

export const CardName = styled.div`
  display: block;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  color: ${props => props.theme.fullBlackText};

  @media (max-width: 1150px) {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }

  @media ${device.laptopL} {
    white-space: pre-line;
  }
`;

export const NoAttachedCards = styled.button`
  margin-bottom: 10px;
  position: relative;
  font-size: 0.94rem;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  width: 100%;
`;

export const NoAttachedCardsLink = styled(Link)`
  position: relative;
  font-size: 0.94rem;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  width: 100%;
  color: ${props => props.theme.linkColor};
  border: 0;
  text-decoration: none;
`;

export const NoCardText = styled.div`
  margin: 0 10px;
  font-family: 'Inter';
  color: ${({ errorColor, theme }) => errorColor && theme.inputErrorColor};
`;

export const AddButton = styled.div`
  color: ${props => props.theme.linkColor};
  font-size: 0.94rem;
  font-family: 'Inter';
`;

export const AttachCardLink = styled(SelectOption)`
  width: 100%;
  color: ${props => props.theme.btnLightColor};
  text-decoration: none;
`;

export const ChoosenMark = styled(ChoosenMarkIcon)``;
